import React, {useState, useEffect} from 'react';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import {handlePush} from "./utils";
import {useHistory} from "react-router-dom";

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591


export default function CustomizedBreadcrumbs({page}) {

  const [level, setLevel] = useState('')
  const [level0, setLevel0] = useState('')
  const [level1, setLevel1] = useState('')
  const [level2, setLevel2] = useState('')
  const [level3, setLevel3] = useState('')


  useEffect(() => {
    let urlParts = window.location.pathname.split("/");
    let baseParts = urlParts.slice(0, 3);
    baseParts[1] = "exam-revision";
    baseParts = baseParts.join("/");
    let subjectParts = urlParts.slice(0, 5);
    subjectParts[1] = "boards"
    subjectParts = subjectParts.join("/");
    let examParts = urlParts.slice(0, 6);
    examParts[1] = "revision"
    examParts = examParts.join("/");
    setLevel(page['page'])
    setLevel0(baseParts)
    setLevel1(subjectParts)
    setLevel2(examParts)
    setLevel3(window.location.pathname)
  }, [page])

  let history = useHistory();

  const handleBreadCrumb = (url) => {
    handlePush(history, url)
  };



  return (
    <Breadcrumbs separator="›"  aria-label="breadcrumb">

      {(level==='regionLevel'|| level==='subjectLevel' || level==='examLevel'|| level === 'paperLevel') ?
        <StyledBreadcrumb
          component="a"
          href={level0}
          label="Home"
          onClick={(event) => handleBreadCrumb(level0)}
        />
        :
        null
      }

      {(level==='subjectLevel'|| level==='examLevel'|| level === 'paperLevel') ?
        <StyledBreadcrumb
          component="a"
          href={level1}
          label="Exam Board"
          onClick={(event) => handleBreadCrumb(level1)}
        />
        :
        null
      }

      {(level==='examLevel'|| level === 'paperLevel') ?
        <StyledBreadcrumb
          component="a"
          href={level2}
          label="Subject"
          onClick={(event) => handleBreadCrumb(level2)}
        />
      :
        null
      }

      {(level === 'paperLevel')  ?
        <StyledBreadcrumb
          href={level3}
          label="Paper"
          onClick={(event) => handleBreadCrumb(level3)}
        />
      :
      null
      }

    </Breadcrumbs>
  );
}