import React, {useEffect, useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Helmet} from "react-helmet";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import {useHistory, useParams} from 'react-router-dom';
import LinearProgress from "@material-ui/core/LinearProgress";
import {useFetch, handlePush, formatUrl, formatCourseName} from "../../shared/utils";
import {enrolViewData} from "../../shared/enrol_pages_date";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import BlogBreadcrumbs from "../../shared/BlogBreadcrumbs";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        padding: theme.spacing(14, 0, 6),
        backgroundColor: '#8236aa45',
        backgroundBlendMode: 'soft-light',
        backgroundSize: 'cover'
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 6px 2px 0 rgba(0,0,0,0.08)'
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
        backgroundSize: 'cover',
        marginBottom: '15px',
        marginTop: '0px',
    },
    cardContent: {
        flexGrow: 1,
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '30ch',
        },
    },
    buttonStyle: {
        width: '30ch'
    },
    drop: {
        width: '30ch',
        margin: '8px',
        paddingTop: '15px',
    },
    footer: {
        backgroundColor: '#2f3030',
        padding: theme.spacing(6),
        color: theme.palette.background.paper,
    },
    preFooter: {
        backgroundColor: '#dfe0e0',
        padding: theme.spacing(4),
        color: theme.palette.background.paper,
    },
}));

export default function TableOfContentsTopics() {

    let { subject } = useParams();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState("success")
    const [notificationMessage, setNotificationMessage] = React.useState(
        "Form submitted! Check your inbox for your first question"
    )
    const [isLoaded, setIsLoaded] = useState(false);
    const [apiData, loading, error] = useFetch('contentTopic');
    const [visited, setVisited] = useState([])
    const [lastViewed, setLastViewed] = useState(null)
    const [wallPaper, setWallPaper] = React.useState(
        'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-25-06-21-19-17-00-exam-revision.jpg'
    )
    const [status, setStatus] = React.useState("Online learning academy");
    const [region, setRegion] = useState('');
    const [subjectId, setSubjectId] = useState('');
    const [subjectName, setSubjectName] = useState('');
    const [catName, setCatName] = useState('');
    const [catId, setCatId] = useState('');


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const vertical = 'top';
    const horizontal = 'center';

    const handleGetContent = (page) => {
        handlePush(history, page)
    };


    useEffect(() => {
        let subjectName = window.location.pathname.split('/')[4]
        let categoryName = window.location.pathname.split('/')[2]
        let categoryId = window.location.pathname.split('/')[3]
        let subjectId = window.location.pathname.split('/')[5]
        setSubjectName(subjectName)
        setSubjectId(subjectId)
        setRegion(categoryName)
        setCatName(categoryName)
        setCatId(categoryId)
        let regionKey = `${categoryName}_wallpaper`
        let statusKey = `${categoryName}_status`
        let imageUrl = enrolViewData[regionKey]
        let wallPaperStatus = enrolViewData[statusKey]
        if (imageUrl){
            setWallPaper(imageUrl)
            setStatus(wallPaperStatus)
        }
        if (localStorage.getItem('visitedCourses')) {
            let visitedArray = JSON.parse(localStorage.getItem('visitedCourses'));
            let arrayOfNumbers = visitedArray.map(Number);
            setVisited(arrayOfNumbers);
            setLastViewed(parseInt(visitedArray[visitedArray.length - 1]));
        }
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[]);

    let history = useHistory();

    return (
        <React.Fragment>
            <Helmet>
                <meta name="description"
                      content="GCSE learning - Tutorial and Exam Preparation" />
                <meta name="theme-color" content="#055b84" />
                <meta name="msapplication-navbutton-color" content="#055b84"/>
                <meta name="apple-mobile-web-app-status-bar-style" content="#055b84"/>
                <title>GCSE - Online learning platform</title>
            </Helmet>
            <CssBaseline />
            <main>
                {/* Hero unit */}
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{vertical, horizontal}}
                    key={vertical + horizontal}
                >
                    <Alert onClose={handleClose} severity={severity}>
                        {notificationMessage}
                    </Alert>
                </Snackbar>
                {isLoaded ?
                    <Container maxWidth="md">
                        <div>
                            <LinearProgress />
                        </div>
                    </Container>
                    :
                    null
                }
                <div style={{
                    // backgroundImage: `url(${wallPaper})`
                }}
                     className={classes.heroContent}>
                    <Container maxWidth="md">
                        <div style={{textAlign: 'left'}}>
                            <Typography
                                component="h1"
                                variant="h5"
                                align="left"
                                color="textPrimary"
                                style={{fontWeight: 'bold', color: '#ac96b9'}}
                                gutterBottom>
                        <span>
                            SUBJECT:
                        </span>
                            </Typography>
                        </div>
                        <div style={{textAlign: 'center'}}>
                            <Typography variant="h6" align="center" style={{color: 'white', fontWeight: 300}}>
                            </Typography>
                        </div>

                        <div className={classes.heroButtons}>
                            <Grid container spacing={2} justify="left">
                                <Grid item elevation={0} >
                                    <Typography style={{fontWeight: 'bold', color: '#191919'}}>
                                        {formatCourseName(subject)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>
                <div>
                    <Container maxWidth="md" className="container-spacer">
                        <div style={{padding: '24px 0px 24px 0px'}}>
                            <BlogBreadcrumbs
                                page={{page: 'topicLevel'}}
                            />
                        </div>
                        <Grid container spacing={4}>
                            {apiData && apiData.map((q, index) => (
                                <Grid item key={q.id} xs={12} sm={6} md={4}>
                                    <Card className={classes.card} elevation={0}>
                                        <CardMedia
                                            className={classes.cardMedia}
                                            image={q.icon}
                                            title={q.name}
                                        />
                                        <CardContent className={classes.cardContent}>
                                            <Typography
                                                gutterBottom
                                                variant="h6"

                                                style={{
                                                    fontWeight: 'bold', textAlign: 'center', fontSize: '1rem'
                                                }}>
                                                {formatCourseName(q.name)}
                                            </Typography>
                                            {/*<Divider variant="middle" style={{ marginBottom: '15px', marginTop: '15px'}} />*/}
                                            {/*<Typography style={{ textAlign: 'center', color: '#686868'}}>*/}
                                            {/*  {card.desc}*/}
                                            {/*</Typography>*/}
                                        </CardContent>
                                        <CardActions style={{marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="secondary"
                                                style={{fontWeight: 800}}
                                                onClick={() => handleGetContent(
                                                    `/blogs-and-articles/${formatUrl(catName)}/${catId}/${subjectName}/${subjectId}/${q.id}`)}
                                            >
                                                Learn More
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </div>
            </main>
            {/* End footer */}

        </React.Fragment>
    );
}