import React, {useEffect, useState} from "react";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import { useLocation, useHistory } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from "@material-ui/core/Button";


const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  largeIcon: {
    width: 80,
    height: 80,
    color: 'green',
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    fontWeight: 'bold'
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

export default function ProSubscriptionCancelSuccess() {
  const classes = useStyles();
  const location = useLocation();
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0)
  });

  useEffect(() => {
    if (location.state === undefined) {
      history.push({
      pathname: '/not-found',
    });
      return;
    }
  }, [location]);

  const handleHome = () => {
    history.push({
      pathname: '/'
    });
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
        <Container className={classes.cardGrid} style={{paddingTop: '10px'}} maxWidth="md">
          <Container maxWidth="md" component="main" className={classes.heroContent}>
            <div style={{textAlign: 'center', paddingTop: '32px'}}>
              <CheckCircleIcon className={classes.largeIcon} />
            </div>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              style={{fontWeight: 'bold', marginBottom: '40px', marginTop: '32px', color: '#7722a2'}}
              gutterBottom>
              Subscription Cancelled
            </Typography>
            <Typography
              align="center"
            >
              Subscription has been successfully cancelled.
            </Typography>
            <Typography align="center">
              You will continue to receive your daily practice question.
              Inorder to get the answers and solution you will need to resubscribe
            </Typography>
            <Typography align="center" style={{fontWeight: 'bold'}}>
              {/*Registered email address: {email}*/}
            </Typography>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => handleHome()}
              style={{fontWeight: 800, fontSize: '16px', padding: '8px', marginTop: '32px',
                backgroundImage: 'linear-gradient(to right,#8336a9,#ec008c,#8336aa)'}}>
                HOMEPAGE
            </Button>
          </Container>
        </Container>
      {/* End hero unit */}
    </React.Fragment>
  );
}

