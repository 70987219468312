import React, {useState, useEffect} from 'react';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import {handlePush} from "./utils";
import {useHistory} from "react-router-dom";

const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591


export default function BlogBreadcrumbs({page, title}) {

    const [level, setLevel] = useState('')
    const [current, setCurrent] = useState('')
    const [level0, setLevel0] = useState('')
    const [level1, setLevel1] = useState('')
    const [level2, setLevel2] = useState('')
    const [level3, setLevel3] = useState('')
    const [level4, setLevel4] = useState('')


    useEffect(() => {
        let urlParts = window.location.pathname.split("/");
        let baseParts = urlParts.slice(0, 2);
        baseParts = baseParts.join("/");
        let subjectParts = urlParts.slice(0, 4);
        debugger;
        subjectParts = subjectParts.join("/");
        let topicParts = urlParts.slice(0, 6);
        topicParts = topicParts.join("/");
        let blogParts = urlParts.slice(0, 7);
        blogParts = blogParts.join("/");
        setCurrent(page['title'])
        setLevel(page['page'])
        setLevel0(baseParts)
        setLevel1(subjectParts)
        setLevel2(topicParts)
        setLevel3(blogParts)
        setLevel4(window.location.pathname)
    }, [page])

    let history = useHistory();

    const handleBreadCrumb = (url) => {
        handlePush(history, url)
    };



    return (
        <Breadcrumbs separator="›"  aria-label="breadcrumb">

            {(level==='regionLevel'|| level==='subjectLevel' || level==='topicLevel'|| level === 'blogLevel' ||  level === 'blog') ?
                <StyledBreadcrumb
                    component="a"
                    href={level0}
                    label="Home"
                    onClick={(event) => handleBreadCrumb(level0)}
                />
                :
                null
            }

            {(level==='subjectLevel'|| level==='topicLevel'|| level === 'blogLevel'||  level === 'blog') ?
                <StyledBreadcrumb
                    component="a"
                    href={level1}
                    label="Subject"
                    onClick={(event) => handleBreadCrumb(level1)}
                />
                :
                null
            }

            {(level==='topicLevel'|| level === 'blogLevel' ||  level === 'blog') ?
                <StyledBreadcrumb
                    component="a"
                    href={level2}
                    label="Topic"
                    onClick={(event) => handleBreadCrumb(level2)}
                />
                :
                null
            }

            {(level === 'blogLevel' || level === 'blog')   ?
                <StyledBreadcrumb
                    href={level3}
                    label="Articles"
                    onClick={(event) => handleBreadCrumb(level3)}
                />
                :
                null
            }

            {(level === 'blog')  ?
                <StyledBreadcrumb
                    href={level4}
                    label={current}
                    onClick={(event) => handleBreadCrumb(level4)}
                />
                :
                null
            }

        </Breadcrumbs>
    );
}