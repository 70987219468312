import React, {useEffect, useState, useRef} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Helmet} from "react-helmet";
import Lottie from "lottie-react";
import animationData from "../assets/animated/Animation-Zen.json";
import DialogComponent from "./DialogComponent";
import { Modal } from '@material-ui/core';
// import wallPaper from './../assets/static/images/OMtmkr.jpg'
// import wallPaper from './../assets/static/images/school-children-learning-South-Africa.jpg'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { usePromiseTracker } from "react-promise-tracker";
// import { trackPromise } from 'react-promise-tracker';
import { countries} from "./configs/ServicedCountries";

import { withStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';

import {headSection, cardsData, howWeHelp, servicesData, serviceList} from './configs/HomeData';

import LinearProgress from "@material-ui/core/LinearProgress";
import Loader from 'react-loader-spinner';
import {useFetch} from "../shared/utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: 'rgba(0,0,0,100)',
    backgroundBlendMode: 'soft-light',
    backgroundSize: 'cover',
    padding: '165px 0px 64px',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    textAlign: 'left'
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // boxShadow: '0 1px 3px 0 rgba(37,32,31,.3)',
    boxShadow: '0 6px 2px 0 rgba(0,0,0,0.001)',
    border: '1px solid #ededed',
    borderRadius: '24px'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
    marginBottom: '16px',
    marginTop: '40px',
  },
  cardContent: {
    flexGrow: 1,
  },
   root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch',
    },
  },
  buttonStyle: {
    width: '30ch'
  },
  drop: {
    width: '30ch',
    margin: '8px',
    paddingTop: '15px',
  },
  footer: {
    backgroundColor: '#2f3030',
    padding: theme.spacing(6),
    color: theme.palette.background.paper,
  },
  preFooter: {
    backgroundColor: '#dfe0e0',
    padding: theme.spacing(4),
    color: theme.palette.background.paper,
  },
  ourCommitment1: {
    // backgroundImage: `url(https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-44-19-zedautos.jpg)`,
    backgroundImage: `url(https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-16-56-25-zedautos.jpg)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backgroundBlendMode: 'luminosity',
    color: 'white'
  }
}));

const WhiteTextTypography = withStyles({
  root: {
    color: "#000000"
  }
})(Typography);


export default function Home({ setSectionRef }) {
  const classes = useStyles();
  const [name, setName] = React.useState('');
  const [apiData, loading, error] = useFetch('home');
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("success")
  const [notificationMessage, setNotificationMessage] = React.useState(
    "Success! Check your inbox for your first question. If you can not find your confirmation email in your normal inbox, it is worth checking in your spam or junk mail section.")
  const [country, setCountry] = React.useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const vertical = 'top';
  const horizontal = 'center';

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleCourseChange = (event) => {
    setCountry(event.target.value);
  };

  const handleButtonClick = (page) => {

    handleNavigate(page)

  };

  useEffect(() => {
    window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
  },[]);

  let history = useHistory();

  function handleNavigate(page) {
    if (page === 'contact'){
      history.push({
        pathname: '/contact',
      });
    } else {
      history.push({
        pathname: '/services/'+page,
      });
    }
  }

  const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress &&
      <div
        style={{
         width: "100%",
         height: "100",
         display: "flex",
         justifyContent: "center",
         alignItems: "center"
        }}
        >
        <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
    );
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (country === ''){
      setOpen(true)
      setSeverity('error')
      setNotificationMessage('Please select a service')
      return;
    }
    handleOpenDialog()
  }

  const allCountries = serviceList.map((val, i) => {
    return (
      <MenuItem key={i} value={val['title'].toLowerCase()}>
        {val['title']}
      </MenuItem>
    )
  });

  return (
    <React.Fragment>
      <Helmet>
        <meta name="description"
              content="Consulting agency dedicated to delivering business services" />
        <meta name="theme-color" content="#055b84" />
        <meta name="msapplication-navbutton-color" content="#055b84"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="#055b84"/>
        <title>Laminin Legal and Accounts - Business Consultancy Agency</title>
      </Helmet>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{vertical, horizontal}}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity}>
            {notificationMessage}
          </Alert>
        </Snackbar>
        <DialogComponent open={dialogOpen} handleClose={handleCloseDialog}/>
        {isLoaded ?
          <Container maxWidth="md">
            <div>
              <LinearProgress />
            </div>
          </Container>
          :
            null
        }
        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <Grid container spacing={3} alignItems="center" style={{ height: '100%' }}>
              <Grid item xs={12} sm={6} className="first-item">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                  <Typography
                    component="h1"
                    variant="h4"
                    align="left"
                    color="textPrimary"
                    style={{fontWeight: 'bold', color: 'white'}}
                    gutterBottom>
                <span
                  // style={{backgroundColor: 'rgb(245, 195, 0)', padding: '0.0em 0.2em 0.0em 0.2em'}}
                >
                  {headSection.topTitle}</span>
                  </Typography>
                </div>
                <div style={{textAlign: 'left'}}>
                  <Typography variant="h6" align="left" style={{color: 'white', fontWeight: 300}}>
                    {headSection.topSubTitle}
                  </Typography>
                </div>
                <div className={classes.heroButtons}>
                  <Grid container spacing={2} justify="left">
                    <Grid item elevation={0} >
                      <Button variant="contained" color="secondary" onClick={() => handleButtonClick('contact')}>
                        CONTACT
                      </Button>
                    </Grid>
                    <Grid item>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} className="second-item">
                <Lottie animationData={animationData} />
              </Grid>
            </Grid>
            {/*<div*/}
            {/*  style={{backgroundPosition: 'center',*/}
            {/*    backgroundRepeat: 'no-repeat',*/}
            {/*    height: '150px',*/}
            {/*    backgroundImage: `url(${beeLogo})`,*/}
            {/*    backgroundSize: 'contain',*/}
            {/*    marginBottom: '15px'*/}
            {/*  }}*/}
            {/*>*/}

            {/*</div>*/}

          </Container>
        </div>

        <Container className={classes.cardGrid} style={{paddingTop: '10px'}} maxWidth="lg">
          <WhiteTextTypography
              component="h1"
              variant="h4"
              align="left"
              style={{fontWeight: 'bold', marginTop: '64px', marginBottom: '40px'}}>
              Everything your business needs
          </WhiteTextTypography>
          <Grid container spacing={4}>
            {servicesData.map((card) => (
              <Grid item key={card.id} xs={12} sm={6} md={4}>
                <Card className={classes.card} elevation={0}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.remote_image}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="h2"
                      style={{fontWeight: 'bold', textAlign: 'center'}}>
                      {card.title}
                    </Typography>
                    <Divider variant="middle" style={{ marginBottom: '15px', marginTop: '15px'}} />
                    <Typography style={{ textAlign: 'center', color: '#686868'}}>
                      {card.desc}
                    </Typography>
                  </CardContent>
                  <CardActions style={{marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      disableElevation={true}
                      style={{fontWeight: 800}}
                      onClick={() => handleButtonClick(card.slug)}>
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        {/*Who we are*/}
        <Container className={classes.cardGrid} style={{padding: '40px 4px 10px 4px'}} maxWidth="false">

          <Grid container spacing={0}>
            <Grid item xs={12} sm={5} md={5}>
              <div className={classes.ourCommitment1} style={{ minHeight: '400px'}}>

              </div>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <div className='commitment-div'>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h2"
                  style={{fontWeight: 'bold', textAlign: 'left'}}>
                  Who We Are
                </Typography>
                <br/>
                <Typography className="commitment-text">
                  Welcome to Laminin Legal and Accounts, your trusted partner in business success! At Laminin, we're
                  not your typical law firm or accountancy practice; we're your one-stop consultancy firm that
                  streamlines all the essential services your business requires. Our mission is to empower businesses
                  and entrepreneurs with comprehensive solutions that enhance efficiency, reduce complexity, and
                  drive growth. With Laminin, you can focus on what you do best while we take care of the rest.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>

        <Container className={classes.cardGrid} maxWidth="lg">
          <WhiteTextTypography
            component="h1"
            variant="h4"
            align="left"
            style={{fontWeight: 'bold', marginTop: '8px', marginBottom: '40px'}}>
            Get some help
          </WhiteTextTypography>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Card className={classes.card} >
                <CardContent className={classes.cardContent}>
                  <form onSubmit={handleSubmit} className={classes.root} noValidate autoComplete="off">
                    <div style={{textAlign: 'center'}}>
                      <FormControl variant="outlined" className={classes.drop}>
                        <InputLabel id="demo-simple-select-outlined-label">Select Service</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={country}
                          onChange={handleCourseChange}
                          label="Select Service"
                          style={{marginTopTop: '15px'}}
                        >
                          {allCountries}
                        </Select>
                      </FormControl>
                      <Button size='large'
                              className={classes.buttonStyle} style={{marginTop: '25px'}}
                              type='submit' onClick={() => setIsLoaded(true)}
                              variant="contained"
                              color="secondary">
                        Speak to an expert
                      </Button>
                      <LoadingIndicator/>
                    </div>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        {/*Our Expertise*/}


        {/* End Our Expertise*/}

        <Container className={classes.cardGrid} style={{paddingTop: '10px', display: 'None'}} maxWidth="md">
          <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              style={{fontWeight: 'bold', marginBottom: '40px'}}
              gutterBottom>
              How We Can Help?
            </Typography>
          <Typography>
            {howWeHelp.howText}
          </Typography>
          <List style={{backgroundColor: 'whitesmoke', marginTop: '20px'}}>
            <Grid container spacing={3}>

              <Grid item xs={12} sm={6}>
                <ListItem>
                <ListItemIcon>
                  <DoneAllIcon />
                </ListItemIcon>
                  <div className='offer'>
                    <ListItemText
                      disableTypography
                      primary={<Typography className='offer' type="body2" style={{ fontWeight: 800, fontSize: '1.1rem', color: '#f5c300'}}>
                        Receive tailored advice for your exact needs</Typography>}
                    />
                  </div>
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItem>
                <ListItemIcon>
                  <DoneAllIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                    primary={<Typography className='offer' type="body2" style={{ fontWeight: 800,  fontSize: '1.1rem', color: 'black'}}>
                      Implementation options available</Typography>}
                />
                </ListItem>
              </Grid>

            </Grid>
          </List>
        </Container>
      </main>
      {/* End footer */}

    </React.Fragment>
  );
}