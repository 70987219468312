import React, {useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Helmet} from "react-helmet";
import MuiAlert from '@material-ui/lab/Alert';
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';
import ReactMarkDownComponent from './../ReactMarkDownComponent';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import {Col, Row} from "reactstrap";
import Divider from '@material-ui/core/Divider';

import Loader from 'react-loader-spinner';

import {useFetch, formatCourseName, handlePush, formatPaper} from "../../shared/utils";
import useLocalStorageState from "use-local-storage-state";

const scrollToRef = (ref) => window.scrollTo({top: ref.current.offsetTop, behavior: 'smooth'})

function displayContent(data) {
  if (data) {
    return(
      <div>
        <ReactMarkDownComponent input={data}/>
      </div>

    )
  }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    backgroundColor: '#ffffff',
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  questionHeaders: {
    fontWeight: 'bold',
    marginBottom: '8px',
    marginTop: '32px',
    color: 'black',
    backgroundColor: 'whitesmoke',
    borderRadius: '4px',
    padding: '4px'
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    backgroundColor: 'white'
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 6px 2px 0 rgba(0,0,0,0.08)'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
    marginBottom: '15px',
    marginTop: '20px',
  },
  cardContent: {
    flexGrow: 1,
  },
  metaData: {
    textAlign: 'center',
    fontWeight: 500,
    color: '#cccccc',
    fontSize: '12px',
    textTransform: 'capitalize'
  },
  metaDataPaper: {
    fontWeight: 500,
    color: '#cccccc',
    fontSize: '12px',
    textTransform: 'capitalize',
    display: 'inline-block',
    padding: '.4em .5em',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    textAlign: 'center',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '3px',
    backgroundColor: '#e4c9f3'
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch',
    },
  },
  buttonStyle: {
    width: '30ch'
  },
  drop: {
    width: '30ch',
    margin: '8px',
    paddingTop: '15px',
  },
  footer: {
    backgroundColor: '#2f3030',
    padding: theme.spacing(6),
    color: theme.palette.background.paper,
  },
  navigateButton: {
    border: '2px solid'
  },
  preFooter: {
    backgroundColor: '#dfe0e0',
    padding: theme.spacing(4),
    color: theme.palette.background.paper,
  },
}));


export default function QuestionView() {
  const classes = useStyles();
  const [next, setNext] = React.useState('/');
  const [previous, setPrevious] = React.useState('/');
  const [viewed, setViewed] = useLocalStorageState('visitedQuestions', [])
  const [severity, setSeverity] = React.useState("success")
  const [examPaper, setExamPaper] = React.useState("")
  const [paperURL, setPaperURL] = React.useState("")
  const [notificationMessage, setNotificationMessage] = React.useState(
    "Form submitted! Check your inbox for your first question"
  )
  const [showAnswer, setShowAnswer] = useState(false)
  const [apiData, loading, error] = useFetch('questionContent');

  const myRef = useRef()
  const executeScroll = () => scrollToRef(myRef)


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  },[]);

  useEffect(() => {
    if (showAnswer){
      executeScroll()
    }

  },[showAnswer])

  useEffect(() => {
    let paper = window.location.pathname.split('/')[2]
    let qID = window.location.pathname.split('/')[3]
    let metaPaper = formatPaper(paper)
    setViewed([...viewed, qID])
    setExamPaper(metaPaper)
    if (apiData.previous_question) {
      let previousURL = `/question/${paper}/${apiData.previous_question}`
      setPrevious(previousURL)
    }
    if (apiData.next_question) {
      let nextURL = `/question/${paper}/${apiData.next_question}`
      setNext(nextURL)
    }
    if (apiData.q_source) {
      let paperLink = `/revise/${apiData.rgn}/${apiData.cn}/${apiData.cid}/${apiData.exam_board}/${paper}/${apiData.q_source}`
      setPaperURL(paperLink)
    }
  },[apiData])

  const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress &&
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
    );
  }

  const handleConfirm = (event) => {
    if (event === 'reveal'){
      setShowAnswer(true)
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
      <Container className={classes.cardGrid} style={{paddingTop: '10px'}} maxWidth="md">
        <Container maxWidth="md" component="main" className={classes.heroContent}>
          <div>
            <Row style={{paddingBottom: '25px', paddingTop: '8px'}}>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                { apiData.previous_question ?
                <div style={{textAlign: 'left'}}>

                  <a href={previous} >
                    <Button size="small" className={classes.navigateButton}>
                      <NavigateBeforeRoundedIcon/>
                      BACK
                    </Button>
                  </a>
                </div>
                : null }
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                { apiData.curr_pos ?
                  <div className="div-center"
                       style={{textAlign: 'center', fontWeight: 500, color: '#cccccc', fontSize: '12px'}}>
                    {apiData.curr_pos} of {apiData.total}
                  </div>
                  : null }
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                { apiData.next_question ?
                <div style={{textAlign: 'right'}}>
                  <a href={next}>
                    <Button size="small" className={classes.navigateButton}>
                      NEXT
                      <NavigateNextRoundedIcon />
                    </Button>
                  </a>
                </div>
                : null }
              </Col>

            </Row>
          </div>
          <Divider style={{backgroundColor: '#0000000f'}} />
          <div style={{marginTop: '16px'}} className={classes.metaDataPaper}>
            <a style={{color: '#2746a2'}} href={paperURL}>{examPaper}</a>
          </div>
          <Typography
            variant="h6"
            align="left"
            className={classes.questionHeaders}
            gutterBottom>
            QUESTION:
          </Typography>

          <div style={{paddingBottom: '64px', paddingTop: '32px'}}>
            <Typography variant="h7" align="left" color="textSecondary" component="p">
              {apiData.q ?
              <div>
                <ReactMarkDownComponent input={apiData.q}/>
              </div>:
                null
              }
            </Typography>
            {showAnswer ?
              <div ref={myRef} style={{paddingTop: '32px'}}>
                <Typography
                  variant="h5"
                  align="left"
                  className={classes.questionHeaders}
                  gutterBottom
                  ref={myRef}
                >
                  MARK SCHEME:
                </Typography>
                <div style={{paddingBottom: '48px', paddingTop: '32px'}}>
                  <Typography variant="h7" align="left" color="textSecondary" component="p">
                    {displayContent(apiData.a && apiData.a)}
                  </Typography>
                </div>
              </div>
              : null}
            <div style={{textAlign: 'left', paddingTop: '32px'}}>
              <Button size='large' className={classes.buttonStyle}
                      style={{marginTop: '25px'}}
                      type='submit'
                      disabled={showAnswer}
                      variant="contained"
                      color="secondary"
                      onClick={() => handleConfirm('reveal')}>
                Reveal Solution
              </Button>
              <LoadingIndicator/>
            </div>
          </div>

        </Container>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
        </Grid>
      </Container>
    </React.Fragment>
  );
}