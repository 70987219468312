import React, { useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Link} from "react-router-dom";
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Helmet} from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles} from "@material-ui/core/styles";
import wallPaper from "../assets/static/images/map-real-bw.jpg";
import LinearProgress from '@material-ui/core/LinearProgress';
import ContactComponent from "./ContactComponent";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(14, 0, 6),
    backgroundImage: `url(${wallPaper})`,
    backgroundColor: 'rgba(0, 0, 0, 0.13)',
    backgroundBlendMode: 'darken',
    backgroundSize: 'cover'
  }
}));

export default function Contact(props) {

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [isLoaded, setIsLoaded] = useState(false);

  return (
  <React.Fragment>
    <Helmet>
    <meta name="description"
          content="Consulting and digital agency dedicated to delivering digital services" />
    <title>Bee Frameworks - IT Consultancy Agency</title>
    </Helmet>
    <CssBaseline />
    <main>
    {/* Hero unit */}
    <div className={classes.heroContent}>
    <Breadcrumb>
      <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
      <BreadcrumbItem active>Contact</BreadcrumbItem>
    </Breadcrumb>
      <Container maxWidth="lg">
        <Typography
          component="h1"
          variant="h4"
          align="left"
          color="textPrimary"
          style={{fontWeight: 'bold'}}
          gutterBottom>
          <span style={{
            backgroundColor: 'rgb(0,0,0)',
            padding: '0.2em 0.2em 0.2em 0.2em',
            color: 'white',
            borderRadius: '5px'
          }}>
            Contact
          </span>
        </Typography>
      </Container>
    </div>
    </main>
    <div className='container'>
      <div>
        {isLoaded ? null :
          <Container maxWidth="md">
            <div>
              <LinearProgress />
            </div>
          </Container>
        }
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Container maxWidth="lg">
              <div style={{height: '300px', borderRadius: "16px"}}>
                <iframe width='100%' height='100%' id='mapcanvas'
                        src='https://maps.google.com/maps?q=London,%20United%20Kingdom&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=&amp;output=embed'
                        frameBorder='0' scrolling='no' marginHeight='0'
                        marginWidth='0' onLoad={() => setIsLoaded(true)}>
                </iframe>
              </div>
            </Container>

            <Container maxWidth="lg">
              <div style={{paddingTop: '30px', paddingBottom: '50px'}}>
                <List>
                  <Grid container spacing={3}>

                    <Grid item xs={12} sm={6}>
                      <ListItem>
                        <ListItemIcon>
                          <PhoneIcon />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={<Typography type="body2"
                                               style={{ fontWeight: 800, fontSize: '1.1rem', color: '#f5c300'}}>
                            Contact</Typography>}
                        />
                      </ListItem>
                      <Typography
                        style={{padding: '16px'}}
                      >
                        0208 087 1636
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ListItem>
                        <ListItemIcon>
                          <MailIcon />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={<Typography type="body2"
                                               style={{ fontWeight: 800,  fontSize: '1.1rem', color: '#f5c300'}}>
                            Address</Typography>}
                        />
                      </ListItem>
                      <Typography
                        style={{padding: '16px'}}
                      >
                        support@lamininlegal.com
                      </Typography>
                    </Grid>

                  </Grid>
                </List>
              </div>
            </Container>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ContactComponent/>
          </Grid>
        </Grid>

      </div>
    </div>
  </React.Fragment>
  );
}