
let credentials = {
  API_URL: "http://localhost:8263/api",
};


if(process.env.REACT_APP_API_ENDPOINT==="development"){
  credentials = {
  API_URL: "http://localhost:8263/api",
  }
}

if(process.env.REACT_APP_API_ENDPOINT==="prod"){
  credentials = {
  API_URL: "https://idailyacademy.com/api",
  }
}

export default credentials;