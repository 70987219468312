import React, {useEffect, useState, useRef, useLayoutEffect} from 'react';
import {useUpdateEffect} from 'react-use';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Helmet} from "react-helmet";
import QuestionAnswerRoundedIcon from '@material-ui/icons/QuestionAnswerRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { useHistory } from 'react-router-dom';
import LinearProgress from "@material-ui/core/LinearProgress";
import {useFetch, handlePush, formatUrl, formatCourseName} from "../../shared/utils";
import ReactMarkDownComponent from './../ReactMarkDownComponent';
import {enrolViewData} from "../../shared/enrol_pages_date";
import beeLogo from '../../assets/static/images/e-lean-gray-scale.png'
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CustomizedBreadcrumbs from "../../shared/CustomBreadcrumbs";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    padding: theme.spacing(14, 0, 6),
    backgroundColor: '#8236aa45',
    backgroundBlendMode: 'soft-light',
    backgroundSize: 'cover'
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 6px 2px 0 rgba(0,0,0,0.08)'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
    marginBottom: '15px',
    marginTop: '20px',
  },
  cardContent: {
    flexGrow: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch',
    },
  },
  buttonStyle: {
    width: '30ch'
  },
  drop: {
    width: '30ch',
    margin: '8px',
    paddingTop: '15px',
  },

  footer: {
    backgroundColor: '#2f3030',
    padding: theme.spacing(6),
    color: theme.palette.background.paper,
  },
  preFooter: {
    backgroundColor: '#dfe0e0',
    padding: theme.spacing(4),
    color: theme.palette.background.paper,
  },
}));

export default function ExamBoardView() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("success")
  const [notificationMessage, setNotificationMessage] = React.useState(
    "Form submitted! Check your inbox for your first question"
  )
  const [isLoaded, setIsLoaded] = useState(false);
  const [apiData, loading, error] = useFetch('examBoards');
  const [subject, setSubject] = useState('')
  const [courseID, setCourseID] = useState('')
  const [reg, setReg] = useState('')
  const [status, setStatus] = React.useState("Online learning academy")


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const vertical = 'top';
  const horizontal = 'center';

  useEffect(() => {
    let subjectName = window.location.pathname.split('/')[3]
    let reg = window.location.pathname.split('/')[2]
    let cid = window.location.pathname.split('/')[4]
    setSubject(subjectName)
    setCourseID(cid)
    setReg(reg)

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });


  },[]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  let history = useHistory();

  const handleGetCourse = (page) => {
    handlePush(history, page)
  }


  return (
    <React.Fragment>
      <Helmet>
        <meta name="description"
              content="GCSE learning - Tutorial and Exam Preparation" />
        <meta name="theme-color" content="#055b84" />
        <meta name="msapplication-navbutton-color" content="#055b84"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="#055b84"/>
        <title>GCSE - Online learning platform</title>
      </Helmet>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{vertical, horizontal}}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity}>
            {notificationMessage}
          </Alert>
        </Snackbar>
        {isLoaded ?
          <Container maxWidth="md">
            <div>
              <LinearProgress />
            </div>
          </Container>
          :
          null
        }
        <div style={{
          // backgroundImage: `url(${wallPaper})`
        }}
             className={classes.heroContent}>
          <Container maxWidth="md">
            <div style={{textAlign: 'left'}}>
              <Typography
                component="h1"
                variant="h4"
                align="left"
                color="textPrimary"
                style={{fontWeight: 'bold', color: '#212121'}}
                gutterBottom>
                <span
                >
                  {apiData.title}</span>
              </Typography>
            </div>
            <div style={{textAlign: 'center'}}>
              <Typography variant="h6" align="center" style={{color: 'white', fontWeight: 300}}>
              </Typography>
            </div>

            <div className={classes.heroButtons}>
              <Grid container spacing={2}>
                <Grid item elevation={0} >
                  {apiData.meta}
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <div>
          <Container maxWidth="md" className="container-spacer">
            <div style={{padding: '24px 0px 24px 0px'}}>
              <CustomizedBreadcrumbs
                page={{page: 'subjectLevel'}}
              />
            </div>
            <div style={{ padding: '24px 0px 24px 0px'}} >
              <Typography variant="h5" style={{ fontWeight: 'bold'}} gutterBottom>Exam Boards</Typography>
            </div>

            <Grid container spacing={4}>
              {apiData.exam_boards && apiData.exam_boards.map((eb, index) => (
                <Grid item key={eb.exam_board_id} xs={12} sm={6} md={4}>
                  <Card className={classes.card} elevation={0}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={eb.exam_board_image}
                      title={eb.exam_board_name}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography
                        gutterBottom
                        variant="h6"

                        style={{
                          fontWeight: 'bold', textAlign: 'center', fontSize: '1rem'
                        }}>
                        {eb.exam_board_name}
                      </Typography>
                      {/*<Divider variant="middle" style={{ marginBottom: '15px', marginTop: '15px'}} />*/}
                      {/*<Typography style={{ textAlign: 'center', color: '#686868'}}>*/}
                      {/*  {card.desc}*/}
                      {/*</Typography>*/}
                    </CardContent>
                    <CardActions style={{marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        style={{fontWeight: 800}}
                        onClick={() => handleGetCourse(
                          `/revision/${reg}/${subject}/${courseID}/${eb.exam_board_id}`)}
                      >
                        SELECT
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
      </main>
      {/* End footer */}

    </React.Fragment>
  );
}