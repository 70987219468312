import React, {useEffect, useState, useRef} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import Loader from "react-loader-spinner";
import { useHistory } from 'react-router-dom';
import credentials from '../config/credentials'

const scrollToRef = (ref) => window.scrollTo({top: ref.current.offsetTop, behavior: 'smooth'})

function displayContent(data) {
  return data.map((q ) => {
    var qp = q.replace('\\n', '')
    if (qp.includes('https')) {
      return(
      <div style={{padding: '16px 0px 16px 0px'}}>
        <img
          // TODO create image processor
          src={qp}
          // src={require(`../assets/images/car-emblems/${
          //             vehicleMakeCountSuccess[v][1]
          //           }`)
          alt="idaily-content"
          className="question-images"
          />
      </div>)
    }
    return (
      <Typography style={{padding: '4px 0px 4px 0px'}} variant="body2" color="textSecondary" align="left">
        {qp}
      </Typography>
  );
  })
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    backgroundColor: 'white',
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    fontWeight: 'bold'
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));


export default function DailyQuestion() {
  const classes = useStyles();
  const [question, setQuestion] = React.useState('');
  const [number, setNumber] = React.useState('')
  const [answer, setAnswer] = React.useState('');
  const [isSubscribed, setIsSubscribed] = React.useState(false)
  const [isLoaded, setIsLoaded] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [course, setCourse] = useState('')
  const [uid, setUid] = useState('')


  const myRef = useRef()
  const executeScroll = () => scrollToRef(myRef)



  useEffect(() => {

    window.scrollTo(0, 0)

  })

  useEffect(() => {
    if (showAnswer){
      executeScroll()
    }


  },[showAnswer])

  useEffect(() => {
    let u = window.location.pathname.split('/')[2]
    let q = window.location.pathname.split('/')[3]

    if (u && q) {
      setUid(u)
      const fetchSendDetails = fetch(`${credentials.API_URL}/idaily-question/${u}/${q}`, {
      method: 'GET',
      headers: {'Content-Type':'application/json'}
    })
    trackPromise(
      fetchSendDetails.then(response=>response.json())
      .then(data => {
        setNumber(data.qn)
        setCourse(data.cn)
        if (data.status === "200") {
          // user is subscribed
          setQuestion(data.q)
          setAnswer(data.a)
          setIsSubscribed(true)
        } else if (data.status === "201") {
          setQuestion(data.q)
        }
        else {
          setError(true)
          setErrorMessage('You are not enrolled to this course')
        }})
    )
    console.log(u)
    }


  }, []);

  const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress &&
      <div
        style={{
         width: "100%",
         height: "100",
         display: "flex",
         justifyContent: "center",
         alignItems: "center"
        }}
        >
        <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
    );
  }

  let history = useHistory();

  const handleConfirm = (event) => {
    if (event === 'reveal'){
      setShowAnswer(true)
    }
    else {
      history.push({
      pathname: `/subscribe-to-pro/${uid}`,
    });
    }
  }


  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
        <Container className={classes.cardGrid} style={{paddingTop: '10px'}} maxWidth="md">
          <Container maxWidth="md" component="main" className={classes.heroContent}>
            <Typography
                component="h1"
                variant="h4"
                align="center"
                // color="textPrimary"
                style={{fontWeight: 'bold', marginBottom: '20px', marginTop: '32px', color: '#6e1698'}}
                gutterBottom>
              Daily Question
            </Typography>
            <Typography
              align="center"
              className="subheading"
              style={{fontWeight: 'bold', marginBottom: '20px', color: '#6e1698'}}
            >
              {course.replace(/_/g, ' ')}
            </Typography>
            <div style={{paddingBottom: '64px', paddingTop: '32px'}}>
              <Typography variant="h6" align="left" color="textSecondary" style={{paddingBottom: '16px'}}>
                Question {number}:
              </Typography>
              <Typography variant="h6" align="left" color="textSecondary" component="p">
                {displayContent(question.split('\n'))}
              </Typography>
              {showAnswer ?
                <div ref={myRef} style={{paddingTop: '32px'}}>
                  <hr/>
                  <Typography
                    variant="h6"
                    align="left"
                    color="textSecondary"
                    style={{paddingBottom: '16px', paddingTop: '16px'}}
                    ref={myRef}
                  >
                    Answer:
                  </Typography>
                  <Typography variant="h6" align="left" color="textSecondary" component="p">
                    {displayContent(answer.split('\n'))}
                  </Typography>
                </div>
                : null}
              <div style={{textAlign: 'left', paddingTop: '32px'}}>
                {isSubscribed ?
                  <Button size='large' className={classes.buttonStyle}
                          style={{marginTop: '25px'}}
                          type='submit'
                          disabled={showAnswer}
                          variant="contained"
                          color="secondary"
                          onClick={() => handleConfirm('reveal')}>
                    Reveal Solution
                  </Button> :
                  <Button size='large' className={classes.buttonStyle}
                          style={{marginTop: '25px'}}
                          type='submit'
                          disabled={isLoaded}
                          variant="contained"
                          color="secondary"
                          onClick={() => handleConfirm('subscribe')}>
                    Subscribe to reveal answer
                  </Button>
                }
                <LoadingIndicator/>
              </div>
            </div>

          </Container>
        </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
        </Grid>
      </Container>
    </React.Fragment>
  );
}