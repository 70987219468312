
export const headSection = {
  logo: 'here',
  topTitle: 'Laminin Legal & Accounts',
  topSubTitle: 'Balancing books and laws: Laminin, providing legal insight and financial foresight.'
}

export const cardsData = [
  {'title': 'Daily Exam Questions', 'price': 250,
    'remote_image': 'https://i.ibb.co/3m9syxb/exam2.png',
    'desc': 'Receive daily exam preparation question, subscribe to PRO fo solutions...', 'id': 2},
  {'title': 'Live Lessons', 'price': 100,
  'remote_image': 'https://i.ibb.co/5KZkYJ2/live-lessons.png',
  'desc': 'Tune in to live lessons 3 days a week...', 'id': 1},
  {'title': 'Personal Tutor', 'price': 95,
    'remote_image': 'https://i.ibb.co/0Yp9J5R/tutor-e-learning.png',
    'desc': 'Online persional tutor to help you with your revision 1 on 1...', 'id': 3},
  ];


export const servicesData = [
  {'title': 'Company Registration', 'price': 250,
    'remote_image': 'https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-18-04-zedautos.jpg',
    'slug': 'registration/company',
    'desc': 'Starting a business is exhilarating, but navigating the complexities of company formation and...', 'id': 2},
  {'title': 'Accounting', 'price': 100,
    'remote_image': 'https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-15-17-zedautos.jpg',
    'slug': 'accounting',
    'desc': 'Navigating the maze of annual accounts can be a daunting task, but with Laminin Legal and\n' +
      'Accounts by your side...', 'id': 1},
  {'title': 'Legal', 'price': 95,
    'remote_image': 'https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-18-47-zedautos.jpg',
    'slug': 'legal',
    'desc': 'In the world of business, the devil is in the details, especially when it comes to services and...', 'id': 3},
];

export const lawServicesData = [
  {"title": "Commercial Leases", "price": 250,
    "remote_image": "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-18-04-zedautos.jpg",
    "desc": "When it comes to commercial leases, precision is paramount. At Laminin Legal and Accounts,\n" +
      "our expertise in drafting and reviewing commercial leases ensures...", "id": 2,
    "slug": "commercial-lease"},
  {"title": "Employment Contracts", "price": 100,
    "remote_image": "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-15-17-zedautos.jpg",
    "desc": "Employment contracts shouldn't be a one-size-fits-all affair. At Laminin Legal and Accounts,\n" +
      "we specialize in crafting employment contracts that...", "id": 1, "slug": "employment-contracts"},
  {"title": "Service and Supplier Agreements", "price": 95,
    "remote_image": "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-18-47-zedautos.jpg",
    "slug": "service-and-supplier-agreements",
    "desc": "In the world of business, the devil is in the details, especially when it comes to services...", "id": 3},
  {"title": "Drafting and Reviewing Terms and Conditions", "price": 95,
    "remote_image": "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-18-47-zedautos.jpg",
    "slug": "terms-and-conditions",
    "desc": "Terms and conditions may be the fine print, but they're the backbone of your business's\n" +
      "legal framework. At Laminin Legal and Accounts, we specialize...", "id": 3},
  {"title": "Shareholders Agreements", "price": 95,
    "remote_image": "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-18-47-zedautos.jpg",
    "slug": "shareholder-agreements",
    "desc": "Shareholder agreements are the heartbeat of a thriving business. At Laminin Legal and\n" +
      "Accounts, we specialize in drafting...", "id": 3},
];

export const companyServicesData = [
  {"title": "Company Formation", "price": 250,
    "remote_image": "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-18-04-zedautos.jpg",
    "desc": "When it comes to commercial leases, precision is paramount. At Laminin Legal and Accounts,\n" +
      "our expertise in drafting and reviewing commercial leases ensures...", "id": 2,
    "slug": "commercial-lease"},
];

export const serviceList = [
  {'title': 'Company Registration'},
  {'title': 'Accounting'},
  {'title': 'Legal'},
]

export const howWeHelp = {
  howText: 'We are passionate about helping clients on how they can utilise their information technology (IT)' +
    ' to optimally achieve their business goals. Let our consultants soothe your IT headache.'
}

export const blogsData = {
  'topTitle': 'Blogs and Articles',
  'headerTitle': 'Categories',
  'categories': [
      {'cat_id': 1, 'cat_name': 'GCSE', 'cat_icon': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-01-02-22-22-44-47-exam-revision.jpg'},
      {'cat_id': 2, 'cat_name': 'A LEVEL', 'cat_icon': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-01-02-22-22-44-47-exam-revision.jpg'},
      {'cat_id': 3, 'cat_name': 'GENERAL', 'cat_icon': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-01-02-22-22-44-47-exam-revision.jpg'}]
}