import React from 'react'
import { withRouter } from 'react-router'
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';


const NotFound = () =>
  <div style={{textAlign: 'center', padding: '72px 0px 64px 0'}}>
    <Typography
              component="h1"
              variant="h4"
              align="center"
              style={{fontWeight: 'bold', marginBottom: '40px', marginTop: '32px', color: '#7722a2'}}
              gutterBottom>
              404 - Page Not Found
            </Typography>
    <Typography>
      Ooops, The link is broken or the page has been moved. Try these page instead:
    </Typography>
    <div style={{paddingTop: '16px'}}>
      <Link href="/">
        Home
      </Link>
    </div>

  </div>

export default withRouter(NotFound)