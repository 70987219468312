import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import gfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'

function Image(props) {
  return <img {...props} style={{maxWidth: '20px'}} />
}

class ReactMarkDownComponent extends Component {
  render() {
    const {input} = this.props
    return (
      <ReactMarkdown
        remarkPlugins={[gfm, remarkMath]}
        rehypePlugins={[rehypeKatex]}
        children={input}
        renderers={{image: Image}}
      />
    )
  }
}

export default ReactMarkDownComponent