import {useEffect, useState} from 'react';
import {trackPromise} from "react-promise-tracker";
import APIcredentials from "../config/ApiCredential";


function useFetch(arg) {
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {

    let fetchUrl = `${APIcredentials.API_URL}/courses`
    async function fetchData() {
      if (arg) {
        if (arg === 'home') {
          fetchUrl = `${APIcredentials.API_URL}/regions`
        }
        if (arg === 'courses') {
          let regionArg = window.location.pathname.split('/')[2]
          fetchUrl = `${APIcredentials.API_URL}/courses/${regionArg}`
        }
        else if (arg === 'examBoards') {
          let courseId = window.location.pathname.split('/')[4]
          fetchUrl = `${APIcredentials.API_URL}/course-exam-boards/${courseId}`
        }
        else if (arg === 'courseContent') {
          let courseId = window.location.pathname.split('/')[4]
          let examBoardId = window.location.pathname.split('/')[5]
          fetchUrl = `${APIcredentials.API_URL}/course-content/${courseId}/${examBoardId}`
        }
        else if (arg === 'paperContent') {
          let paperId = window.location.pathname.split('/')[7]
          fetchUrl = `${APIcredentials.API_URL}/paper/${paperId}`
        }
        else if (arg === 'paperInsert') {
          let paperId = window.location.pathname.split('/')[3]
          fetchUrl = `${APIcredentials.API_URL}/past-paper-insert/${paperId}`
        }
        else if (arg === 'questionContent') {
          let qId = window.location.pathname.split('/')[3]
          fetchUrl = `${APIcredentials.API_URL}/question/${qId}`
        }
        else if (arg === 'contentSubject') {
          let sId= window.location.pathname.split('/')[3]
          fetchUrl = `${APIcredentials.API_URL}/content-subject/${sId}`
        }
        else if (arg === 'contentTopic') {
          let subjectId= window.location.pathname.split('/')[5]
          fetchUrl = `${APIcredentials.API_URL}/content-topic/${subjectId}`
        }
        else if (arg === 'contentList') {
          let topicId= window.location.pathname.split('/')[6]
          fetchUrl = `${APIcredentials.API_URL}/content-list/${topicId}`
        }
        const fetchAvailableCourses = fetch(fetchUrl, {
          method: 'GET',
          headers: {'Content-Type': 'application/json'}
        })
        trackPromise(
          fetchAvailableCourses.then(response => response.json())
            .then(data => {
                if (data) {
                  setResponse(data.obj)
                } else {
                }
              }
            ).catch((err) => {
            setError("ERROR SOMETHING WENT WRONG")
          })
        )
      } else {
        // do something else
      }
      setLoading(false)
    }

    fetchData();
  }, []);
  return [response, loading, error];
}

function formatCourseName(name) {
  return name.replace(/_/g, " ").toUpperCase()
}

function formatPaper(name) {
  return name.replace(/-/g, " ")
}

function formatUrl(url){
  return url.replace(/\s+/g, '-').toLowerCase();
}

function handlePush(history, page) {
  history.push({
    pathname: page,
  });
}

export {useFetch, formatCourseName, formatUrl, handlePush, formatPaper}