import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert'; // Adjust import based on your MUI version

export default function ContactComponent() {
  const [name, setName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [message, setMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!name.trim() || !contactNumber.trim() || !message.trim()) {
      setSnackbarMessage('Please fill in all fields.');
      setSnackbarOpen(true);
      return;
    }
    console.log('Name: ' + name + ', Contact Number: ' + contactNumber + ', Message: ' + message);
    // Handle form submission here
  };

  return (
    <div>
      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Card elevation={0}>
              <CardContent>
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <div style={{textAlign: 'center'}}>
                    <FormControl variant="outlined" fullWidth margin="normal">
                      <TextField
                        label="Name"
                        variant="outlined"
                        size="small"
                        style={{marginBottom: '24px'}}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                      />

                      <TextField
                        label="Contact Number"
                        variant="outlined"
                        size="small"
                        style={{marginBottom: '24px'}}
                        value={contactNumber}
                        onChange={(event) => setContactNumber(event.target.value)}
                      />

                      <TextField
                        label="Message"
                        variant="outlined"
                        multiline
                        style={{marginBottom: '24px'}}
                        rows={4}
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                      />

                      <Button size='large'
                              style={{marginTop: '25px'}}
                              type='submit'
                              variant="contained"
                              color="secondary">
                        Send
                      </Button>
                    </FormControl>
                  </div>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
