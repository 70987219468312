export const data = {
  whoWeAre: 'We are iDaily Academy, an E-Learning platform offering a new way to learn. Our aim is to transform the traditional ' +
    'learning patterns whilst making education affordable'
}

export const whyChooseUs = {
  body: [
    {
      "Simplified Convenience": "Say goodbye to juggling between multiple service providers. Laminin is your " +
        "all-in-one solution, offering a seamless blend of legal, accounting, and consulting services. Our " +
        "integrated approach ensures a smoother, more efficient experience for your business."
    },
    {
      "Expertise Across the Spectrum": "Our team comprises seasoned professionals with diverse backgrounds and " +
        "specialties. Whether it's legal advice, tax optimization, or strategic consulting, we've got you covered. " +
        "You can trust our experts to provide insightful solutions tailored to your specific needs."
    },
    {
      "Cost-Effective Solutions": "We understand the value of your resources. Laminin's integrated services not " +
        "only save you time but also reduce costs associated with hiring multiple firms. Our holistic approach " +
        "ensures you get the best value for your investment."
    },
    {
      "Strategic Insights": "In today's ever-evolving business landscape, staying ahead of the curve is crucial. " +
        "Laminin not only addresses your immediate needs but also offers strategic guidance to help you navigate " +
        "future challenges and opportunities."
    },
    {
      "Compliance and Peace of Mind": "Compliance can be a minefield for businesses. Laminin keeps you on the " +
        "right side of the law and financial regulations, ensuring your operations run smoothly and worry-free."
    },
    {
      "Tailored Solutions": "We understand that every business is unique. That's why our services are highly " +
        "customizable. Whether you're a startup, a small business, or an established enterprise, we adapt our " +
        "solutions to match your specific goals and aspirations."
    },
    {
      "A Trusted Partner": "Laminin is not just a service provider; we're your partner in growth. We build " +
        "lasting relationships with our clients, fostering trust and reliability. Your success is our success."
    }
  ],
}