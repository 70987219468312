import React from "react";



export const commercialLeaseData = {
  title: "Commercial Lease",
  coverImage: "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-01-02-24-10-24-22-zedautos.jpg",
  topSubTitle: "Unlock Your Lease Potential with Laminin\n" +
    "When it comes to commercial leases, precision is paramount. At Laminin Legal and Accounts,\n" +
    "our expertise in drafting and reviewing commercial leases ensures you're making smart,\n" +
    "strategic decisions for your business. Here's why you should choose us:",
  body: [
    {
      "Tailored Agreements": "We draft leases that align perfectly with your unique business needs, " +
        "protecting your interests and maximizing your lease benefits."
    },
    {
      "Risk Mitigation": "Our thorough review process identifies potential pitfalls, shielding you from costly " +
        "disputes and ensuring your lease terms are airtight."
    },
    {
      "Compliance Confidence": "Don't get caught in the legal web. Laminin ensures your leases comply with the latest " +
        "regulations, providing peace of mind and a strong legal foundation."
    },
    {
      "Negotiation Savvy": "We're skilled negotiators, advocating for favourable terms on your behalf. Let us secure" +
        " the best possible lease agreements, tailored to your advantage."
    },
    {
      "Time and Cost Savings": "Our efficient processes save you time and resources, allowing you to focus on your " +
        "core business while we handle the intricacies of lease drafting and review."
    }
  ],
  conclusion: "Choosing Laminin Legal and Accounts for your commercial leasing needs means partnering with a team that " +
    "not only understands the legal landscape but also prioritizes your business's success and growth. " +
    "Our comprehensive approach to commercial leases—from drafting and negotiation to compliance and risk " +
    "management—ensures that every aspect of your lease agreement is strategically aligned with your business " +
    "objectives. With Laminin, you gain more than a service provider; you gain a partner dedicated to ensuring " +
    "your leasing decisions contribute to your business's strength and stability. Let us help you navigate the " +
    "complexities of commercial leasing, so you can focus on what you do best: growing your business."
}

export const shareholderAgreementsData = {
  title: "Shareholders Agreements",
  coverImage: "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-01-02-24-10-24-22-zedautos.jpg",
  topSubTitle: "Elevate Your Shareholder Harmony with Laminin\n" +
    "Shareholder agreements are the heartbeat of a thriving business. At Laminin Legal and\n" +
    "Accounts, we specialize in drafting and reviewing shareholder agreements that harmonize\n" +
    "interests, protect investments, and ensure long-term success. Here's why entrusting us\n" +
    "makes a significant impact:",
  body: [
    {
      "Strategic Alignment": "Our agreements ensure all shareholders are on the same page, clarifying roles, " +
        "responsibilities, and expectations to foster synergy and unity."
    },
    {
      "Risk Mitigation": "We identify potential pitfalls and provide solutions, shielding your business from " +
        "internal disputes that could disrupt operations and jeopardize growth."
    },
    {
      "Customization": "Laminin tailors agreements to match your unique business goals, ensuring your document " +
        "reflects your company's specific needs and aspirations."
    },
    {
      "Legal Precision": "Our experts draft agreements with meticulous attention to detail, adhering to the latest " +
        "laws and regulations, minimizing legal vulnerabilities."
    },
    {
      "Investor Confidence": "Well-structured shareholder agreements attract investors by demonstrating a commitment " +
        "to transparency, governance, and accountability."
    }
  ],
  conclusion: "In the complex landscape of corporate governance, Laminin Legal and Accounts stands as your ally, " +
    "ensuring that your shareholder agreements not only meet legal requirements but also align with your strategic " +
    "vision. With our expertise, you can navigate shareholder relations with confidence, knowing that your business is " +
    "built on a solid foundation of clear, comprehensive agreements. Let us help you turn shareholder agreements " +
    "into a tool for success, fostering a climate of mutual respect and shared goals. Choose Laminin, where your " +
    "business's future is secured with precision and care."
}

export const termsAndConditionsData = {
  title: "Drafting and Reviewing Terms and Conditions",
  coverImage: "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-01-02-24-10-24-22-zedautos.jpg",
  topSubTitle: "Solidify Your Business Foundation with Laminin\n" +
    "Terms and conditions may be the fine print, but they're the backbone of your business's legal framework. " +
    "At Laminin Legal and Accounts, we specialize in drafting and reviewing terms and conditions that fortify " +
    "your operations. Here's why having us on your side is a game-changer:",
  body: [
    {
      "Legal Fortitude": "Our experts ensure that your terms and conditions align with the latest laws and " +
        "regulations, safeguarding your business from unforeseen legal risks."
    },
    {
      "Clarity and Consistency": "Clear, well-defined terms and conditions prevent misunderstandings and disputes, " +
        "bolstering your reputation and customer trust."
    },
    {
      "Customization": "We tailor your terms and conditions to your specific industry, ensuring they serve as a " +
        "strategic asset rather than just another legal document."
    },
    {
      "Risk Mitigation": "Protect your business interests with meticulously drafted terms that reduce liability " +
        "and enhance your control over crucial aspects of your operation."
    },
    {
      "Professional Image": "Strong terms and conditions reflect professionalism and commitment to excellence, " +
        "setting you apart in the eyes of clients and partners."}
  ],
  conclusion: "Don't underestimate the power of well-drafted terms and conditions. With Laminin, you're not just " +
    "protecting your business; you're laying the foundation for growth and success. Choose us for terms and " +
    "conditions that make a statement – one of strength, reliability, and trustworthiness. Your business " +
    "deserves nothing less."
}

export const serviceAndSupplierAgreementsData = {
  title: "Service and Supplier Agreements",
  topSubTitle: "Unlock Successful Partnerships with Laminin\n" +
    "In the world of business, the devil is in the details, especially when it comes to services and supplier " +
    "agreements. Laminin Legal and Accounts is your go-to partner for drafting or reviewing these essential " +
    "agreements, and here's why it matters:",
  coverImage: "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-01-02-24-10-24-22-zedautos.jpg",
  body: [
    {
      "Risk Mitigation": "Our expert touch ensures that your agreements are airtight, reducing the risk of " +
        "disputes, unexpected costs, and supply chain disruptions."
    },
    {
      "Tailored Solutions": "We don't believe in one-size-fits-all. Laminin customizes agreements to match your " +
        "unique needs, giving you the upper hand in negotiations."
    },
    {
      "Legal Clarity": "Clarity is key. Our precise language and compliance expertise mean your agreements are " +
        "legally sound, protecting your interests and bottom line."
    },
    {
      "Cost Efficiency": "With Laminin on your side, you're making cost-effective decisions that positively " +
        "impact your bottom line. Our contracts optimize resources and streamline operations."
    },
    {
      "Supplier Confidence": "Our agreements build trust with your suppliers, fostering strong, lasting " +
        "partnerships that benefit both parties."}
  ],
  conclusion: "In the complex world of services and supplier agreements, Laminin is your strategic ally. Don't " +
    "leave the future of your business to chance. Choose Laminin for agreements that set you up for success. " +
    "Partner with us today, and let's build a brighter, more efficient tomorrow together."
};

export const employmentContractsData = {
  title: "Employment Contracts",
  coverImage: "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-01-02-24-10-24-22-zedautos.jpg",
  topSubTitle: "Elevate Your Workforce with Laminin\n" +
    "Employment contracts shouldn't be a one-size-fits-all affair. At Laminin Legal and Accounts, we specialize in " +
    "crafting employment contracts that reflect your business's unique needs and protect your interests. " +
    "Here's why partnering with us is essential:",
  body: [
    {
      "Tailored Protection": "Our contracts are customized to safeguard your business from potential HR pitfalls, " +
        "ensuring that your workforce aligns with your company's vision."
    },
    {
      "Legal Precision": "Don't leave room for ambiguity. Our experts ensure every detail in your employment " +
        "contracts complies with the latest employment laws and regulations, reducing legal risks."
    },
    {
      "Conflict Resolution": "Laminin's contracts provide clear guidelines for dispute resolution, " +
        "minimizing potential conflicts and maintaining a harmonious work environment."
    },
    {
      "Retention and Engagement": "Well-drafted employment contracts can improve employee retention and " +
        "engagement. We help you create agreements that motivate and retain top talent."
    },
    {
      "Time and Resource Savings": "Let us handle the complexities of contract drafting and review, " +
        "so you can focus on nurturing a productive workforce and growing your business."}
  ],
  conclusion: "Elevate your workforce relationships with Laminin. Our employment contracts aren't just " +
    "paperwork; they're your assurance of a well-structured, legally compliant, and engaged team. " +
    "Choose Laminin for contracts that work as hard as you do."
};

export const companyFormationAndSecretarialData = {
  title: "Launch and Lead with Laminin",
  coverImage: "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-01-02-24-16-39-36-zedautos.jpg",
  service: "Company Formation and Secretarial Services",
  topSubTitle: "Starting a business is exhilarating, but navigating the complexities of company formation and " +
    "secretarial duties can be daunting. At Laminin Legal and Accounts, we're your compass to success, " +
    "and here's why you should trust us:",
  body: [
    {
      "Seamless Setup": "We make company formation a breeze, handling all the paperwork, registrations, and legal " +
        "requirements, so you can hit the ground running."
    },
    {
      "Compliance Champions": "Laminin ensures your company remains compliant with ever-changing regulations, " +
        "safeguarding your reputation and financial stability."
    },
    {
      "Time and Resource Savings": "Let us manage your secretarial tasks, freeing your time and resources to " +
        "focus on growing your business and nurturing your vision."
    },
    {
      "Strategic Guidance": "Beyond compliance, we offer strategic insights to optimize your company's governance and " +
        "operations, enhancing efficiency and minimizing risk."
    },
    {
      "Professional Image": "Our meticulous attention to detail and expert handling of secretarial duties project " +
        "professionalism and reliability to clients and partners."}
  ],
  conclusion: "Don't embark on your business journey alone. Choose Laminin for company formation and secretarial " +
    "services that lay the groundwork for your success. Let's launch your dream and lead your " +
    "business to new heights together."
};

export const accountancyData = {
  title: "Accounting Services",
  coverImage: "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-01-02-24-16-37-27-zedautos.jpg",
  topSubTitle: "Accountancy",
  body: [
    {
      "Accuracy and Compliance": "Our experts meticulously prepare and file your annual accounts, ensuring they are " +
        "accurate and compliant with all legal and regulatory requirements. No more sleepless nights worrying about " +
        "compliance pitfalls."
    },
    {
      "Time and Resource Savings": "Free up your valuable time and resources by outsourcing this critical but " +
        "time-consuming task to Laminin. Focus on growing your business, while we take care of the financial " +
        "nitty-gritty."
    },
    {
      "Tax Efficiency": "We go beyond the basics, identifying opportunities for tax optimization, potentially " +
        "saving you money and maximizing your financial efficiency."
    },
    {
      "Risk Mitigation": "Our comprehensive approach includes a thorough review of your financial statements, " +
        "helping you identify and mitigate risks before they become costly problems."
    },
    {
      "Strategic Insights": "Your annual accounts hold valuable insights into your business's financial health. " +
        "Laminin's experts don't just crunch numbers; we provide you with actionable insights to help you make " +
        "informed strategic decisions."
    },
    {
      "Peace of Mind": "With Laminin, you can trust that your annual accounts are in capable hands. Say goodbye to " +
        "last-minute rushes and financial stress. We ensure your accounts are prepared with precision and " +
        "submitted on time, every time."
    },
    {
      "Payroll": "Laminin Legal and Accounts handles your payroll with precision and care. Our " +
        "streamlined services ensure your employees are paid accurately and on time, while you focus on building a " +
        "happy, productive team."
    },
  ],
  conclusion: "Choose Laminin Legal and Accounts for your annual accounts, and let us be your financial compass. " +
    "Our dedicated team is ready to steer you in the right direction, ensuring your financial success. " +
    "Don't just meet the compliance requirements; surpass them with Laminin."
};


export const corporateTaxData = {
  title: "Corporate Tax & Self-Assessment",
  coverImage: "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-01-02-24-16-37-27-zedautos.jpg",
  topSubTitle: "Tax season doesn't have to be a headache! At Laminin Legal and Accounts, " +
    "we're here to simplify your corporate tax and self-assessment tax returns. Our services " +
    "are designed to not only simplify the process but also save you time, and ensure you're getting " +
    "the most out of your finances.",
  body: [
    {
      "Expert Precision": "Our tax experts are seasoned professionals with an eagle eye for detail. " +
        "We'll ensure your tax returns are accurate, minimizing the risk of costly errors and maximizing your financial benefits."
    },
    {
      "Timely Filing": "No more last-minute rushes or missed deadlines. Laminin guarantees that your " +
        "tax returns are filed promptly and efficiently, helping you avoid penalties and fines."
    },
    {
      "Tax Optimization": "We don't just fill out forms; we strategize. Laminin helps you explore " +
        "tax-saving opportunities, potentially reducing your tax liability and leaving more money in your pocket."
    },
    {
      "Stress-Free Compliance": "Tax regulations can be complex and ever-changing. Let us handle the " +
        "complexities while you focus on what you do best – running your business. Laminin ensures you remain " +
        "compliant with the latest tax laws."
    },
    {
      "Tailored Solutions": "We understand that every business is unique. Our tax services are tailored to your " +
        "specific circumstances, ensuring you receive personalized, expert guidance that fits your needs."
    },
    {
      "Peace of Mind": "With Laminin, you can rest easy, knowing your tax affairs are in capable hands. " +
        "Say goodbye to tax-related stress and hello to financial clarity."
    }
  ],
  conclusion: "Don't let tax season overwhelm you. Choose Laminin Legal and Accounts for your corporate tax and " +
    "self-assessment tax returns. We're not just filling out forms; we're optimizing your financial future. " +
    "Let's minimize the hassle and maximize your returns, together. Trust Laminin for tax excellence!"
};


const servicesData = {
  "commercial-lease": commercialLeaseData,
  "shareholder-agreements": shareholderAgreementsData,
  "terms-and-conditions": termsAndConditionsData,
  "service-and-supplier-agreements": serviceAndSupplierAgreementsData,
  "employment-contracts": employmentContractsData,
  "company": companyFormationAndSecretarialData,
  "annual-accounts": accountancyData,
  "self-assessments": corporateTaxData,
};

export default servicesData;

export const sectionOne = {
  topTitle: 'Web Design & Digital Marketing',
  topSubTitle: 'You know you need a web site, but what do you need and who do you choose? ' +
    'At BeeFrameworks in Leeds, West Yorkshire, we design and develop search engine friendly, hand-crafted websites and we offer digital ' +
    'marketing services aimed at helping your business increase traffic and generate leads.',
  cardOneTitle: 'Web Design',
  cardOneDesc: [
    'Understanding your priorities and type of website you require is the first step.',
    'Followed by a road map, be it a WordPress or bespoke site. We create a strategy to succeed.',
    'Then it\'s time to get to work. Our team gets things done.'
  ],
  cardOneDescPt2: 'Because of our pay monthly pricing' +
    '    \'model, it means our services are affordable without compromising on quality.',
  cardTwoTitle: 'Digital Marketing',
  cardTwoDesc: [
    'SEO (Search Engine Optimisation)', 'Social Media Marketing', 'Email Marketing', 'Pay Per Click Management'
  ]
}

export const sectionTwo = {
  topTitle: 'IT Support & Software Development',
  topSubTitle: 'We provide cost-effective IT support services, we have experience in implementing and supporting\n' +
    '          Microsoft-based IT infrastructures. We build and maintain software by leveraging cutting edge technology.\n' +
    '          In short, we provide an end-to-end support for all your software needs.',
  cardOneTitle: 'IT Support',
  cardOneDesc: ['Microsoft Systems', 'Cyber Security', 'Computer Networks', 'Cloud Solutions',
  ],
  cardTwoTitle: 'Software Development',
  cardTwoDesc: [
    'We take great pride in helping our clients run their business processes as efficient as possible',
    'Our goal is to synchronise your objectives with our technical solution. Our experts will help you improve system ' +
    'reliability, performance and productivity through innovation'
  ]
}
