
let APIcredentials = {
  API_URL: "http://localhost:5000/api",
};


if(process.env.REACT_APP_API_ENDPOINT==="development"){
  APIcredentials = {
    API_URL: "http://localhost:5000/api",
  }
}

if(process.env.REACT_APP_API_ENDPOINT==="prod"){
  APIcredentials = {
    API_URL: "https://idailyacademy.com/idaily-api",
  }
}

export default APIcredentials;