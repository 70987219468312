import React, {useEffect} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import wallPaper from "../assets/static/images/OMtmkr.jpg";
import {data, whyChooseUs} from "./configs/AboutData";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(14, 0, 6),
    // backgroundImage: `url(${wallPaper})`,
    backgroundColor: 'rgba(0, 0, 0, 0.013)',
    backgroundBlendMode: 'darken',
    backgroundSize: 'cover'
  },
  sections: {
  },
  sectionTitles: {
    paddingBottom: '30px',
    fontWeight: 'bolder',
    paddingTop: '64px',
  },
  coreValues: {
    fontWeight: 'bold'
  }
}));


export default function About(props) {

  const classes = useStyles();

  useEffect(() => {

  }, [])

  return (
  <React.Fragment>
    <Helmet>
    <meta name="description"
          content="Consulting agency dedicated to delivering business services" />
    <title>Laminin Legal and Accounts - Business Consultancy Agency</title>
    </Helmet>
    <CssBaseline />
    <main>
    {/* Hero unit */}
    <div className={classes.heroContent}>
    <Breadcrumb>
      <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
      <BreadcrumbItem active>About</BreadcrumbItem>
    </Breadcrumb>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography
              component="h1"
              variant="h4"
              align="left"
              color="textPrimary"
              style={{fontWeight: 'bold'}}
              gutterBottom>
                <span style={{
                  backgroundColor: 'rgb(0,0,0)',
                  padding: '0.2em 0.2em 0.2em 0.2em',
                  color: 'white',
                  borderRadius: '5px'
                }}>
                  About Us
                </span>
            </Typography>
            <br/>
            <Typography >
              Laminin Legal and Accounts is committed to being a catalyst for your business's success. Our team of
              dedicated professionals is passionate about helping you achieve your objectives and maximize your
              potential. We&#39;re not just consultants; we're your advocates, your allies in the journey toward
              success.
            </Typography>
            <br/>
            <Typography>
              Discover the difference Laminin can make for your business. Experience the convenience, expertise,
              and peace of mind that comes from having all your essential services under one roof. Contact us
              today to explore how we can tailor our solutions to meet your specific needs and drive your business
              forward.
            </Typography>

          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              src="https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-02-02-24-18-10-05-zedautos.jpg"
              style={{borderRadius: "16px", width: "90%", height: "auto"}}
              alt="Laminin Legal Services"/>

          </Grid>
        </Grid>
      </Container>
    </div>
    </main>
    <div>
      <div className={classes.sections}>
        <Container maxWidth="lg">
          <Typography
          component="h1"
          variant="h5"
          align="left"
          color="textPrimary"
          className={classes.sectionTitles}
          gutterBottom>
            Why Choose Laminin?
          </Typography>
          <ul>
            {whyChooseUs.body.map((item, index) => {
              const key = Object.keys(item)[0];
              const value = item[key];
              return (
                <li key={index}>
                  <Typography component="span" variant="body1" style={{fontWeight: 'bold'}}>
                    {key}: </Typography>
                  <Typography component="span" variant="body1">{value}</Typography>
                  <br/>
                  <br/>
                </li>
              );
            })}
          </ul>
        </Container>
      </div>
    </div>
  </React.Fragment>
  );
}