import React from "react";


export const LegalData = {
  title: "Legal Services",
  coverImage: "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-01-02-24-10-24-22-zedautos.jpg",
  topSubTitle: "",
  servicesOffered: [
    {"title": "Commercial Leases", "price": 250,
      "remote_image": "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-18-04-zedautos.jpg",
      "desc": "When it comes to commercial leases, precision is paramount. At Laminin Legal and Accounts,\n" +
        "our expertise in drafting and reviewing commercial leases ensures...", "id": 2,
      "slug": "legal/commercial-lease"},
    {"title": "Employment Contracts", "price": 100,
      "remote_image": "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-15-17-zedautos.jpg",
      "desc": "Employment contracts shouldn't be a one-size-fits-all affair. At Laminin Legal and Accounts,\n" +
        "we specialize in crafting employment contracts that...", "id": 1, "slug": "legal/employment-contracts"},
    {"title": "Service and Supplier Agreements", "price": 95,
      "remote_image": "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-18-47-zedautos.jpg",
      "slug": "legal/service-and-supplier-agreements",
      "desc": "In the world of business, the devil is in the details, especially when it comes to services...", "id": 3},
    {"title": "Drafting and Reviewing Terms and Conditions", "price": 95,
      "remote_image": "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-18-47-zedautos.jpg",
      "slug": "legal/terms-and-conditions",
      "desc": "Terms and conditions may be the fine print, but they're the backbone of your business's\n" +
        "legal framework. At Laminin Legal and Accounts, we specialize...", "id": 3},
    {"title": "Shareholders Agreements", "price": 95,
      "remote_image": "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-18-47-zedautos.jpg",
      "slug": "legal/shareholder-agreements",
      "desc": "Shareholder agreements are the heartbeat of a thriving business. At Laminin Legal and\n" +
        "Accounts, we specialize in drafting...", "id": 3},
  ]
}

export const AccountancyData = {
  title: "Accountancy Services",
  coverImage: "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-01-02-24-16-37-27-zedautos.jpg",
  topSubTitle: "",
  servicesOffered: [{"title": "Annual Accounts", "price": 250,
    "remote_image": "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-18-04-zedautos.jpg",
    "desc": "Navigating the maze of annual accounts can be a daunting task, but with Laminin Legal and\n" +
      "Accounts by your side, it becomes...", "id": 2,
    "slug": "accounting/annual-accounts"},
    {"title": "Tax Returns", "price": 100,
      "remote_image": "https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-15-17-zedautos.jpg",
      "desc": "Tax season doesn't have to be a headache! At Laminin Legal and Accounts, we're here to simplify\n" +
        "your corporate tax and self-assessment tax returns. Our services are designed...", "id": 1,
      "slug": "accounting/self-assessments"}],
}



const servicesHomeData = {
  "legal": LegalData,
  "accounting": AccountancyData

  // Add more services as needed
};

export default servicesHomeData;
