import React, { useContext } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom';
import logo from '../assets/static/images/lamin-legal-logo-2.png'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // display: 'none',
    fontWeight: 900,
    color: '#000',
    // fontStyle: 'italic',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      textAlign: 'center',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    textTransform: 'capitalize',
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


  const handleMobileMenuClose = (event) => {
    const page = event.currentTarget.textContent
    setMobileMoreAnchorEl(null);
    handleNavigate(page)
  };

  let history = useHistory();

  function handleNavigate(page) {
    if (page === 'HOME') {
      history.push('/');
    }
    if (page === 'ABOUT US') {
      history.push('/about-us');
    }
    if (page === 'ACCOUNTANCY') {
      history.push('/services/accounting');
    }
    if (page === 'LEGAL') {
      history.push('/services/legal');
    }
    if (page === 'CONTACT') {
      history.push('/contact');
    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMobileMenuClose}>
        HOME
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        LEGAL
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        ACCOUNTANCY
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        ABOUT US
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
       CONTACT
      </MenuItem>

    </Menu>
  );


  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        style={{ backgroundImage: 'linear-gradient(to right, white , white)', boxShadow: '0 5px 15px 0 rgba(0,0,0,.05)',
          color: 'black', textTransform: 'capitalize' }}>
        <Container maxWidth="lg">
        <Toolbar>

            <img
              style={{ cursor: 'pointer',height: '100px', padding: '5px' }}
              src={logo}
              onClick={() => history.push('/')}
              alt="logo">
            </img>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {/*<Button color="inherit" onClick={handleMobileMenuClose}>Blogs</Button>*/}
              <Button color="inherit" onClick={handleMobileMenuClose}>LEGAL</Button>
              <Button color="inherit" onClick={handleMobileMenuClose}>ACCOUNTANCY</Button>
              <Button color="inherit" onClick={handleMobileMenuClose}>ABOUT US</Button>
              <Button color="inherit" onClick={handleMobileMenuClose}>CONTACT</Button>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </div>

        </Toolbar>
        </Container>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
