import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Helmet} from "react-helmet";
import beeLogo from '../../assets/static/images/e-lean-gray-scale.png'
// import wallPaper from './../assets/static/images/OMtmkr.jpg'
// import wallPaper from './../../assets/static/images/school-children-learning-england.jpg'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';

import { withStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';

import { headSection, cardsData, howWeHelp } from './../configs/HomeData';

import LinearProgress from "@material-ui/core/LinearProgress";
import Loader from 'react-loader-spinner';

import {useFetch, formatCourseName, formatUrl, handlePush} from "../../shared/utils";
import {enrolViewData} from "../../shared/enrol_pages_date";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(14, 0, 6),
    // marginTop: '40px',
    // backgroundImage: `url(${wallPaper})`,
    backgroundColor: '#8236aa45',
    backgroundBlendMode: 'soft-light',
    backgroundSize: 'cover'
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // boxShadow: '0 1px 3px 0 rgba(37,32,31,.3)',
    boxShadow: '0 6px 2px 0 rgba(0,0,0,0.08)'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
    marginBottom: '15px',
    marginTop: '20px',
  },
  cardContent: {
    flexGrow: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch',
    },
  },
  buttonStyle: {
    width: '30ch'
  },
  drop: {
    width: '30ch',
    margin: '8px',
    paddingTop: '15px',
  },
  footer: {
    backgroundColor: '#2f3030',
    padding: theme.spacing(6),
    color: theme.palette.background.paper,
  },
  preFooter: {
    backgroundColor: '#dfe0e0',
    padding: theme.spacing(4),
    color: theme.palette.background.paper,
  },
}));

const WhiteTextTypography = withStyles({
  root: {
    color: "#000000"
  }
})(Typography);


export default function Enrol() {
  const classes = useStyles();
  const [name, setName] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("success")
  const [notificationMessage, setNotificationMessage] = React.useState(
    "Form submitted! Check your inbox for your first question"
  )
  const [course, setCourse] = React.useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [region, setRegion] = React.useState('');
  const [apiData, loading, error] = useFetch('courses');
  const [wallPaper, setWallPaper] = React.useState(
    'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-25-06-21-19-17-00-exam-revision.jpg'
  )
  const [status, setStatus] = React.useState("Online learning academy")


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const vertical = 'top';
  const horizontal = 'center';

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleCourseChange = (event) => {
    setCourse(event.target.value);
  };

  const handleButtonClick = (page) => {
    handleNavigate(page)
  };

  const handleGetCourse = (page) => {
    handleCourseContent(page)
  };

  useEffect(() => {
    let regionArg = window.location.pathname.split('/')[2]
    let regionKey = `${regionArg}_wallpaper`
    let statusKey = `${regionArg}_status`
    let imageUrl = enrolViewData[regionKey]
    let wallPaperStatus = enrolViewData[statusKey]
    if (imageUrl){
      setWallPaper(imageUrl)
      setStatus(wallPaperStatus)
    }
    setRegion(regionArg)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  },[]);

  let history = useHistory();

  function handleNavigate(page) {
    history.push({
      pathname: '/contact',
    });
  }

  function handleCourseContent(page) {
    history.push({
      pathname: page,
    });
  }

  function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress &&
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
    );
  }


  const handleSubmit = (event) => {
    event.preventDefault()

    if ((name === '') || (course === '')) {
      setOpen(true)
      setSeverity('error')
      setNotificationMessage('All fields are required')
      return;
    }

    const a = validateEmail(name)
    if (!a) {
      setOpen(true)
      setSeverity('error')
      setNotificationMessage('Enter a valid email address')
      return;
    }

    let userSearchQuery = {q: name, c: course, siteID: 'learning'}

    const fetchSendDetails = fetch('https://idailyacademy.com/api/contact-form/', {
      method: 'POST',
      body: JSON.stringify(userSearchQuery),
      headers: {'Content-Type':'application/json'}
    })
    trackPromise(
      fetchSendDetails.then(response => {
        if (response.status === 200) {
          setName('')
          setCourse('')
          setOpen(true)
          setSeverity('success')
          setNotificationMessage('Form submitted! Check your inbox for your first question')
          setIsLoaded(false)
        }
        return response
      })
    )
  }


  return (
    <React.Fragment>
      <Helmet>
        <meta name="description"
              content="GCSE learning - Tutorial and Exam Preparation" />
        <meta name="theme-color" content="#055b84" />
        <meta name="msapplication-navbutton-color" content="#055b84"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="#055b84"/>
        <title>GCSE - Online learning platform</title>
      </Helmet>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{vertical, horizontal}}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity}>
            {notificationMessage}
          </Alert>
        </Snackbar>
        {isLoaded ?
          <Container maxWidth="md">
            <div>
              <LinearProgress />
            </div>
          </Container>
          :
          null
        }
        <div style={{
          backgroundImage: `url(${wallPaper})`
        }}
             className={classes.heroContent}>
          <Container maxWidth="sm">
            <div
              style={{backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '150px',
                backgroundImage: `url(${beeLogo})`,
                backgroundSize: 'contain',
                marginBottom: '15px'
              }}
            >

            </div>
            <div style={{textAlign: 'center'}}>
              <Typography
                component="h1"
                variant="h4"
                align="center"
                color="textPrimary"
                style={{fontWeight: 'bold', color: '#212121'}}
                gutterBottom>
                <span
                  // style={{backgroundColor: 'rgb(245, 195, 0)', padding: '0.0em 0.2em 0.0em 0.2em'}}
                >
                  {headSection.topTitle}</span>
              </Typography>
            </div>
            <div style={{textAlign: 'center'}}>
              <Typography variant="h6" align="center" style={{color: '#8236aa', fontWeight: 300}}>
                {status}
              </Typography>
            </div>

            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item elevation={0} >
                  <Button variant="contained" color="secondary" onClick={() => handleButtonClick('services')}>
                    More Info
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined"
                          style={{color: 'white', border: '1px solid #fff'}}
                          onClick={() => handleButtonClick('contact')}
                  >
                    Get in Touch
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <Container className={classes.cardGrid} style={{paddingTop: '10px', display: 'None'}} maxWidth="md">
          <WhiteTextTypography
            component="h1"
            variant="h5"
            align="center"
            style={{fontWeight: 'bold', marginTop: '64px', marginBottom: '20px'}}
          >
            Learn on your phone and pass your exams.
          </WhiteTextTypography>
          <div style={{textAlign: 'center', marginBottom: '64px'}}>
            <Typography style={{fontWeight: 'bold'}}> Here's how it works:</Typography>
            <ol className="center">
              <Typography><li>  Sign up to receive a real exam question in your mailbox daily.</li>
                <li>Try to solve the questions! It's fun and sharpens your mind.</li>
                <li><span style={{fontWeight: 'bold'}}>(Subscribe to PRO)</span> Receive solutions with complete analysis </li>
              </Typography>
            </ol>
          </div>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Card className={classes.card} >
                <CardContent className={classes.cardContent}>
                  <div style={{textAlign: 'center'}}>
                    <WhiteTextTypography
                      component="h6"
                      variant="h6"
                      align="center"
                      style={{fontWeight: 'bold', marginTop: '8px', marginBottom: '16px'}}
                    >
                      Enrol and get the first revision question by email. It's free.

                    </WhiteTextTypography>
                  </div>
                  <form onSubmit={handleSubmit} className={classes.root} noValidate autoComplete="off">
                    <div style={{textAlign: 'center'}}>
                      <TextField
                        id="outlined-name"
                        label="Email"
                        value={name}
                        onChange={handleNameChange}
                        variant="outlined"
                        style={{paddingTop: '15px'}}
                      />
                      <FormControl variant="outlined" className={classes.drop}>
                        <InputLabel id="demo-simple-select-outlined-label">Select Course</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={course}
                          onChange={handleCourseChange}
                          label="Select Course"
                          style={{marginTopTop: '15px'}}
                        >
                          {apiData.map(apiData =>
                            <MenuItem value={apiData.course_name}>{formatCourseName(apiData.course_name)}</MenuItem>)
                          }
                        </Select>
                      </FormControl>
                      <Button size='large'
                              className={classes.buttonStyle} style={{marginTop: '25px'}}
                              type='submit' onClick={() => setIsLoaded(true)}
                              variant="contained"
                              color="secondary">
                        Enrol
                      </Button>
                      <LoadingIndicator/>
                    </div>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>


        {/*Our Expertise*/}

        <Container className={classes.cardGrid} maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            style={{fontWeight: 'bold', marginBottom: '40px'}}
            gutterBottom>
            Courses
          </Typography>
          <Grid container spacing={4}>
            {apiData.map((subject) => (
              <Grid item key={subject.course_id} xs={12} sm={6} md={4}>
                <Card className={classes.card} elevation={0}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={subject.course_image}
                    title={subject.course_name}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography
                      gutterBottom
                      variant="h6"

                      style={{
                        fontWeight: 'bold', textAlign: 'center', fontSize: '1rem'
                      }}>
                      {formatCourseName(subject.course_name)}
                    </Typography>
                    {/*<Divider variant="middle" style={{ marginBottom: '15px', marginTop: '15px'}} />*/}
                    {/*<Typography style={{ textAlign: 'center', color: '#686868'}}>*/}
                    {/*  {card.desc}*/}
                    {/*</Typography>*/}
                  </CardContent>
                  <CardActions style={{marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      style={{fontWeight: 800}}
                      onClick={() => handleGetCourse(
                        `/boards/${region}/${formatUrl(formatCourseName(subject.course_name))}/${subject.course_id}`)}>
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        {/* End Our Expertise*/}

        <Container className={classes.cardGrid} style={{paddingTop: '10px', display: 'None'}} maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            style={{fontWeight: 'bold', marginBottom: '40px'}}
            gutterBottom>
            How We Can Help?
          </Typography>
          <Typography>
            {howWeHelp.howText}
          </Typography>
          <List style={{backgroundColor: 'whitesmoke', marginTop: '20px'}}>
            <Grid container spacing={3}>

              <Grid item xs={12} sm={6}>
                <ListItem>
                  <ListItemIcon>
                    <DoneAllIcon />
                  </ListItemIcon>
                  <div className='offer'>
                    <ListItemText
                      disableTypography
                      primary={<Typography className='offer' type="body2" style={{ fontWeight: 800, fontSize: '1.1rem', color: '#f5c300'}}>
                        Receive tailored advice for your exact needs</Typography>}
                    />
                  </div>
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItem>
                  <ListItemIcon>
                    <DoneAllIcon />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={<Typography className='offer' type="body2" style={{ fontWeight: 800,  fontSize: '1.1rem', color: 'black'}}>
                      Implementation options available</Typography>}
                  />
                </ListItem>
              </Grid>

            </Grid>
          </List>
        </Container>
      </main>
      {/* End footer */}

    </React.Fragment>
  );
}