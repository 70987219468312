import React, {useEffect, useRef, useState} from "react";
import axios from 'axios';
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import Loader from "react-loader-spinner";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {makeStyles} from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField/TextField";
import FormHelperText from '@material-ui/core/FormHelperText';

import credentials from '../config/credentials'


const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
    formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formElements: {
    paddingTop: theme.spacing(5),
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  drop: {
    width: '100%',
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    fontWeight: 'bold'
  },
   root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch',
    },
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 50)

const CheckoutForm = ({success}) => {
  const classes = useStyles();
  const stripe = useStripe()
  const elements = useElements();

  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  const [formState, setFormState] = useState('idle');
  const [plan, setPlan] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false);
  const [planError, setPlanError] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState('')
  const [invalidPlan, setInvalidPlan] = useState('')
  const [status, setStatus] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState(false);
  const [cardErrorMessage, setCardErrorMessage] = useState('')


  const handlePlanChange = (event) => {
    setInvalidPlan('')
    setPlanError(false)
    setPlan(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmailError(false)
    setEmail(event.target.value);
  };

  let history = useHistory();


  useEffect(() => {
    window.scrollTo(0, 0);
    let u = window.location.pathname.split('/')[2];

    if (u) {
      const fetchSendDetails = fetch(`${credentials.API_URL}/idaily-question/${u}`, {
      method: 'GET',
      headers: {'Content-Type':'application/json'}
    })
    trackPromise(
      fetchSendDetails.then(response=>response.json())
      .then(data => {
        if (data.status === "200") {
          // user email
          setEmail(data.user)
        } else if (data.status === "201") {
        }
        else {
        }})
    )
    }
    else {
      history.push({
      pathname: '/',
    });
    }
    
  }, []);

  function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress &&
      <div
        style={{
         width: "100%",
         height: "100",
         display: "flex",
         justifyContent: "center",
         alignItems: "center"
        }}
        >
        <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
    );
  };

  function uiErrorHandler(message) {
    console.log(message);
    setError(true);
    setCardErrorMessage(message);
    setFormState('idle');
    executeScroll();
  }

  const handleSubmitSub = async (event) => {
    event.preventDefault()

    if (!email || !plan) {
      if (!email) {
        setEmailError(true)
      }
      if (!plan) {
        setPlanError(true)
        setInvalidPlan('Select Plan')
      }
      setFormState('idle')
      return;
    }

    if (!validateEmail(email)) {
      setEmailError(true)
      setInvalidEmail('Enter valid email')
      setFormState('idle')
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    const cardElements = elements.getElement(CardElement)
    if (status !== '') {
      
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElements,
          billing_details: {
            email: email,
          },
        },
      });
      if (result.error) {
        uiErrorHandler('Your card has been declined. Please try another one');
        cardElements.clear()
      } else {
        success(email);
      }
    } else {

      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElements,
        billing_details: {
          email: email,
        },
      });
      setFormState('submitting');

      if (result.error) {
        uiErrorHandler(result.error.message)
      } else {
        const priceId = "price_1HCt8oFHoF6Us4V2XAsEZdUY"
        const payload = {
          customerEmail: email,
          paymentMethodId: result.paymentMethod.id,
          priceId: priceId,
        };

        const res = await trackPromise(axios.post(`${credentials.API_URL}/idaily-create-subscription/`, payload));

        // eslint-disable-next-line camelcase
        const {client_secret, status} = res.data;

        if (status === '400') {
          uiErrorHandler('Something went wrong. Refresh the page and try again')
          cardElements.clear()
        }

        if (status === '401') {
          uiErrorHandler('Email not found. Enrol on a course first then subscribe')
          cardElements.clear()
        }

        if (status === 'requires_action') {
          setStatus(status);
          setClientSecret(client_secret);
          stripe.confirmCardPayment(client_secret).then(function(result) {
            if (result.error) {
              uiErrorHandler('Your card has been declined. Please try another one')
              cardElements.clear()
            } else {
              success(email)
            }
          });
        } else if (status === 'incomplete') {
          stripe.confirmCardPayment(client_secret).then(function(result) {
            if (result.error) {
              uiErrorHandler('Your card has been declined. Please try another one')
              cardElements.clear()
            } else {
              success(email)
            }
          })
        } else if (status === 'succeeded') {
          success(email)
        } else if (status === 'requires_payment_method') {
          uiErrorHandler('Your card has been declined. Please try another one')
          cardElements.clear()
        } else {
          uiErrorHandler('Your card has been declined. Please try another one')
          cardElements.clear()
        }
      }
    }
  };


  return <form ref={myRef}
    onSubmit={handleSubmitSub}
    className={classes.root} style={{padding: '8px 8px 20px 8px', maxWidth: '500px', margin: '0 auto'}}
    noValidate autoComplete="off">
    {error ?
      <div >
        <Alert disabled severity="error">{cardErrorMessage}</Alert>
      </div>
      :
    null}

    <div >
      <div className={classes.formElements}>
        <TextField
          id="outlined-name"
          label="Email"
          error={emailError}
          helperText={invalidEmail}
          value={email}
          disabled={email}
          onChange={handleEmailChange}
          variant="outlined"
          autoComplete="off"
          style={{ width: '100%', margin: '0px'}}
        />
      </div>
      <div className={classes.formElements}>
        <FormControl variant="outlined" className={classes.drop} error={!!planError}>
        <InputLabel id="demo-simple-select-outlined-label">Select Plan</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={plan}
          disabled={formState === 'submitting'}
          error={planError}
          onChange={handlePlanChange}
          label="Select Course"
          style={{marginTopTop: '15px', color: 'black'}}
          isClearable
        >
          <MenuItem value={'PRO'}>PRO Daily Questions ($0.99/month)</MenuItem>
        </Select>
        <FormHelperText>{invalidPlan}</FormHelperText>
        </FormControl>
      </div>

      <div className={classes.formElements}>
        <CardElement options={{hidePostalCode: true}}/>
      </div >

      <div className={classes.formElements}>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          type="submit"
          disabled={formState === 'submitting'}
          fullWidth
          style={{fontWeight: 800, fontSize: '16px', padding: '8px',
            backgroundImage: 'linear-gradient(to right,#8336a9,#ec008c,#8336aa)'}}>
          Subscribe
        </Button>
        <LoadingIndicator/>
      </div>
    </div>
  </form>
}

const stripePromise = loadStripe("pk_test_51HCs67FHoF6Us4V2OIwJ2aaecZtBl5HUV91Z35Nip7Gkx7BWrAVzbCg8jyfanfJEMnNzld92ZZq2Z1Q6GCp7Fuho00ftxbNXhJ");


export default function ProSubscription() {
  const classes = useStyles();
  const [status, setStatus] = useState('ready')

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  let history = useHistory();

  const handleSuccess = (email) => {
    history.push({
      pathname: '/subscribe-to-pro-success',
      state: {userEmail: email}
    });
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
        <Container className={classes.cardGrid} style={{paddingTop: '10px', paddingLeft: '0px', paddingRight: '0px'}} maxWidth="md">
          <Container maxWidth="md" component="main" className={classes.heroContent}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              style={{fontWeight: 'bold', marginBottom: '40px', marginTop: '32px', color: '#7722a2'}}
              gutterBottom>
            Subscribe
            </Typography>
            <div style={{TextAlign: 'center'}}>
              <Typography
                align="center"
                color="textPrimary"
                style={{marginBottom: '32px', marginTop: '32px'}}
                gutterBottom>
                  Receive all the questions and answers in your inbox daily!
              </Typography>
            </div>
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  success={handleSuccess}
                  disableButton={() => {
                    setStatus('not')
                  }}
                />
              </Elements>
            </Container>
        </Container>
    </React.Fragment>
  );
}

