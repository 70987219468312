import React from 'react';
import { Typography, Divider, Box } from '@material-ui/core'; // or '@mui/material' for v5

function DividerWithText() {
  return (
    <Box display="flex" alignItems="center" textAlign="center">
      <Box flexGrow={1}>
        <Divider style={{ backgroundColor: '#eaeaea' }} />
      </Box>
      <Box paddingX={1}> {/* Adjust the padding as needed */}
        <Typography variant="body1">OR</Typography>
      </Box>
      <Box flexGrow={1}>
        <Divider style={{ backgroundColor: '#eaeaea' }} />
      </Box>
    </Box>
  );
}

export default DividerWithText;