import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import UnsubscribeIcon from '@material-ui/icons/Unsubscribe';
import Loader from "react-loader-spinner";
import credentials from './../config/credentials';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  largeIcon: {
    width: 80,
    height: 80,
    color: 'green',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    fontWeight: 'bold'
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function Disenroll() {
  const classes = useStyles();
  const [course, setCourse] = React.useState('');
  const [name, setName] = React.useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [disenrolled, setDisenrolled] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {

    window.scrollTo(0, 0)

  }, [])

  useEffect(() => {
    let u = window.location.pathname.split('/')[2]
    let c = window.location.pathname.split('/')[3]


    if (u && c) {
      let userSearchQuery = {u: u, c: c, t: 'check'}
      const fetchSendDetails = fetch(`${credentials.API_URL}/disenroll/`, {
      method: 'POST',
      body: JSON.stringify(userSearchQuery),
      headers: {'Content-Type':'application/json'}
    })
    trackPromise(
      fetchSendDetails.then(response=>response.json())
      .then(data => {
        if (data.status === "200") {
          setName(u)
          setCourse(c)
        } else {
          setError(true)
          setErrorMessage('You are not enrolled to this course')
        }})
    )
    }


  }, []);

  const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress &&
      <div
        style={{
         width: "100%",
         height: "100",
         display: "flex",
         justifyContent: "center",
         alignItems: "center"
        }}
        >
        <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
    );
  }

  const handleGoHome = (page) => {

    handleNavigate(page)

  };

  let history = useHistory();

  function handleNavigate(page) {
    history.push({
      pathname: '/',
    });
  }

  const handleConfirm = (event) => {
    setIsLoaded(true)

    let userSearchQuery = {u: name, c: course, t: 'disenroll'}

    const fetchSendDetails = fetch(`${credentials.API_URL}/disenroll/`, {
      method: 'POST',
      body: JSON.stringify(userSearchQuery),
      headers: {'Content-Type':'application/json'}
    })
    trackPromise(
      fetchSendDetails.then(response=>response.json())
      .then(data => {
        if (data.status === "200") {
          setDisenrolled(true)
          setIsLoaded(false)
          setName('')
          setCourse('')
          window.scrollTo(0, 0)
        } else {
          setError(true)
          setErrorMessage('You are not enrolled to this course')
        }})
    )
  }

  const courseName = capitalizeFirstLetter(course)

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
      {(error) ?
        (<div>
          <Container maxWidth="sm" component="main" className={classes.heroContent}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              style={{fontWeight: 'bold', marginBottom: '40px', marginTop: '64px'}}
              gutterBottom>
              {errorMessage}
          </Typography>
          </Container>
        </div>)

        :

      ((disenrolled) ?
        (<Container maxWidth="sm" component="main" className={classes.heroContent}>
          <div style={{textAlign: 'center', paddingTop: '32px'}}>
              <CheckCircleIcon className={classes.largeIcon} />
            </div>
          <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              style={{fontWeight: 'bold', marginBottom: '40px', marginTop: '64px'}}
              gutterBottom>
            You have successfully withdrawn from this course
          </Typography>
          <div style={{textAlign: 'center'}}>
            <Button size='large' className={classes.buttonStyle}
                    style={{marginTop: '25px'}}
                    type='submit'
                    variant="contained"
                    color="secondary"
                    onClick={(event) => handleGoHome(event)}>
              GO TO HOMEPAGE
            </Button>
          </div>
        </Container>)

        :

        (<Container maxWidth="sm" component="main" className={classes.heroContent}>
          <Typography
              component="h1"
              variant="h4"
              align="center"
              style={{fontWeight: 'bold', marginBottom: '40px', marginTop: '32px', color: '#7722a2'}}
              gutterBottom>
            Opt out
          </Typography>
          <div style={{paddingBottom: '64px', paddingTop: '32px', border: '1px solid #d7d7d7', borderRadius: '6px'}}>

            <Typography
              align="center"
              style={{color: 'black'}}
            >
              We are sorry to see you leave!
            </Typography>
            <div style={{textAlign: "center", paddingTop: '32px'}}>
              <UnsubscribeIcon style={{width: 80, height: 80}}/>
            </div>
            <div style={{paddingTop: '16px', paddingBottom: '16px', textAlign: 'center'}}>
              <Typography
                style={{color: 'black'}}
              >
                You are about to withdraw from
                <span style={{fontWeight: 'bold'}}> {courseName}</span> daily revision.
                <div>You will no longer receive daily revision questions</div>
                <div>Click below to confirm</div>
              </Typography>
            </div>
            <div style={{textAlign: 'center'}}>
              <Button size='large' className={classes.buttonStyle}
                      style={{marginTop: '25px'}}
                      type='submit'
                      disabled={isLoaded}
                      variant="contained"
                      color="secondary"
                      onClick={(event) => handleConfirm(event)}>
                Opt out
              </Button>
              <a style={{margin: '16px'}} href="https://idailyacademy.com">I'd rather stay</a>
              <LoadingIndicator/>
            </div>
          </div>

        </Container>))

      }
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">

        </Grid>
      </Container>
    </React.Fragment>
  );
}