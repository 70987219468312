import React, {useEffect, useState} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Helmet} from "react-helmet";
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { useHistory } from 'react-router-dom';
import LinearProgress from "@material-ui/core/LinearProgress";
import {useFetch, handlePush, formatUrl} from "../../shared/utils";
import ReactMarkDownComponent from './../ReactMarkDownComponent';
import {enrolViewData} from "../../shared/enrol_pages_date";
import CustomizedBreadcrumbs from "../../shared/CustomBreadcrumbs";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    padding: theme.spacing(14, 0, 6),
    backgroundColor: '#8236aa45',
    backgroundBlendMode: 'soft-light',
    backgroundSize: 'cover'
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 6px 2px 0 rgba(0,0,0,0.08)'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
    marginBottom: '15px',
    marginTop: '20px',
  },
  cardContent: {
    flexGrow: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch',
    },
  },
  buttonStyle: {
    width: '30ch'
  },
  drop: {
    width: '30ch',
    margin: '8px',
    paddingTop: '15px',
  },
  footer: {
    backgroundColor: '#2f3030',
    padding: theme.spacing(6),
    color: theme.palette.background.paper,
  },
  preFooter: {
    backgroundColor: '#dfe0e0',
    padding: theme.spacing(4),
    color: theme.palette.background.paper,
  },
}));

export default function TableOfContentsCourse() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("success")
  const [notificationMessage, setNotificationMessage] = React.useState(
    "Form submitted! Check your inbox for your first question"
  )
  const [isLoaded, setIsLoaded] = useState(false);
  const [apiData, loading, error] = useFetch('courseContent');
  const [visited, setVisited] = useState([])
  const [lastViewed, setLastViewed] = useState(null)
  const [wallPaper, setWallPaper] = React.useState(
    'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-25-06-21-19-17-00-exam-revision.jpg'
  )
  const [status, setStatus] = React.useState("Online learning academy");
  const [region, setRegion] = useState('');
  const [subjectID, setSubjectID] = useState('');
  const [examBoard, setExamBoardID] = useState('')


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const vertical = 'top';
  const horizontal = 'center';

  const handleGetPaper = (_subject, _title, _subjectId) => {
    let clean_subject = formatUrl(_subject)
    let clean_title = formatUrl(_title)
    let url = `/revise/${region}/${clean_subject}/${subjectID}/${examBoard}/${clean_title}/${_subjectId}`
    handlePush(history, url)
  };


  useEffect(() => {
    let regionArg = window.location.pathname.split('/')[2]
    let subjectID = window.location.pathname.split('/')[4]
    let examBoardID = window.location.pathname.split('/')[5]
    setSubjectID(subjectID)
    setExamBoardID(examBoardID)
    setRegion(regionArg)
    let regionKey = `${regionArg}_wallpaper`
    let statusKey = `${regionArg}_status`
    let imageUrl = enrolViewData[regionKey]
    let wallPaperStatus = enrolViewData[statusKey]
    if (imageUrl){
      setWallPaper(imageUrl)
      setStatus(wallPaperStatus)
    }
    if (localStorage.getItem('visitedCourses')) {
      let visitedArray = JSON.parse(localStorage.getItem('visitedCourses'));
      let arrayOfNumbers = visitedArray.map(Number);
      setVisited(arrayOfNumbers);
      setLastViewed(parseInt(visitedArray[visitedArray.length - 1]));
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  },[]);

  let history = useHistory();

  function handleNavigate(page) {

    history.push({
      pathname: '/pricing',
    });
  }


  return (
    <React.Fragment>
      <Helmet>
        <meta name="description"
              content="GCSE learning - Tutorial and Exam Preparation" />
        <meta name="theme-color" content="#055b84" />
        <meta name="msapplication-navbutton-color" content="#055b84"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="#055b84"/>
        <title>GCSE - Online learning platform</title>
      </Helmet>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{vertical, horizontal}}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity}>
            {notificationMessage}
          </Alert>
        </Snackbar>
        {isLoaded ?
          <Container maxWidth="md">
            <div>
              <LinearProgress />
            </div>
          </Container>
          :
          null
        }
        <div style={{
          // backgroundImage: `url(${wallPaper})`
        }}
             className={classes.heroContent}>
          <Container maxWidth="md">
            <div style={{textAlign: 'left'}}>
              <Typography
                component="h1"
                variant="h4"
                align="left"
                color="textPrimary"
                style={{fontWeight: 'bold', color: 'white'}}
                gutterBottom>
                <span
                >
                  {apiData.title}</span>
              </Typography>
            </div>
            <div style={{textAlign: 'center'}}>
              <Typography variant="h6" align="center" style={{color: 'white', fontWeight: 300}}>
              </Typography>
            </div>

            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="left">
                <Grid item elevation={0} >
                  {apiData.meta}
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <div>
          <Container maxWidth="md" className="container-spacer">
            <div style={{padding: '24px 0px 24px 0px'}}>
              <CustomizedBreadcrumbs
                page={{page: 'examLevel'}}
              />
            </div>
          {apiData.questions && apiData.questions.map((q, index )=>
            <div>
              <List className={classes.root} aria-label="questions">
                <ListItem
                  className="list-style"
                  button

                  onClick={() => handleGetPaper(apiData.title, q.qp_title, q.qp_id)}
                >
                  <ListItemIcon style={{color: "#8137a9", fontWeight: 'bold'}}>
                    {/*<QuestionAnswerRoundedIcon />*/}
                    {index + 1}.
                  </ListItemIcon>
                  <ListItemText>
                    <div style={{fontWeight: 'bold'}}>{q.qp_title}</div>
                  </ListItemText>
                  {visited &&
                  visited.includes(q.qp_id)
                    ?
                    <VisibilityRoundedIcon
                      className={lastViewed === q.qp_id ? 'last-viewed': 'not-last-viewed'}
                    />
                    :
                    null }
                </ListItem>

              </List>
            </div>
          )}
          </Container>
        </div>
      </main>
      {/* End footer */}

    </React.Fragment>
  );
}