// AlertDialogSlide.js
import React from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import Typography from '@material-ui/core/Typography';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogComponent({ open, handleClose }) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <div style={{textAlign: 'center', paddingTop: '32px'}}>
          <PhoneIcon style={{ fontSize: '4rem', color: 'green' }}/>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <div style={{textAlign: 'center', padding: '0px 32px'}}>
            <div>
              <Typography
                gutterBottom
                variant="h6"
                component="h2"
                style={{fontWeight: 'bold', textAlign: 'center'}}>
                Call us:
              </Typography>
            </div>
            <br/>
            <div>
              <Typography
                gutterBottom
                variant="h6"
                component="h2"
                style={{fontWeight: 'bold', textAlign: 'center'}}>
                <a href="tel:0208 087 1636" style={{ textDecoration: 'none', color: 'blue' }}>
                  0208 087 1636
                </a>
              </Typography>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
