import React, {useEffect, useState, useRef} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import Loader from "react-loader-spinner";
import { useHistory } from 'react-router-dom';
import credentials from '../config/credentials'
import ReactMarkDownComponent from './ReactMarkDownComponent'
import MarkdownRender from "./MarkdownRender";

const scrollToRef = (ref) => window.scrollTo({top: ref.current.offsetTop, behavior: 'smooth'})


const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  questionArea: {
    display: 'block',
    width: '100%',
    minHeight: '300px'
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    backgroundColor: 'white',
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    fontWeight: 'bold'
  },
  textHeading: {
    fontWeight: 'bold',
    color: '#6e1698'
  },
  styleGuide: {
    color: '#787878',
    fontSize: '12px'
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));


export default function DailyQuestionLivePreview() {
  const classes = useStyles();
  const [question, setQuestion] = React.useState('');
  const [showAnswer, setShowAnswer] = useState(false)
  const textAreaRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState('');

  const myRef = useRef()
  const executeScroll = () => scrollToRef(myRef)

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  useEffect(() => {
    if (showAnswer){
      executeScroll()
    }

  },[showAnswer])


  let history = useHistory();


  const handleSubmit = (event) => {
    textAreaRef.current.select();
    document.execCommand('copy');
    event.target.focus();
    setCopySuccess('Copied!');
    // alert('An essay was submitted: ' + question);
    event.preventDefault();
  }

  const handleChange = (event) => {
    setQuestion(event.target.value)
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
      <Container className={classes.cardGrid} style={{paddingTop: '10px'}} maxWidth="md">
        <Container maxWidth="md" component="main" className={classes.heroContent}>
          <Typography
            component="h1"
            variant="h4"
            align="center"
            // color="textPrimary"
            style={{fontWeight: 'bold', marginBottom: '20px', marginTop: '32px', color: '#6e1698'}}
            gutterBottom>
            Live Preview
          </Typography>
          <div className={classes.styleGuide}>
            <p>STYLING GUIDE:</p>
            <div>IMAGE SIZE: (img-50, img-100, img-150, img-200, img-400, img-600, img-800) </div>
            <div>Example: ![img-200](https://example.com/image.jpg)</div>
            <br/>
            <div>MATHS FORMULAE EXAMPLES: </div>
            <div>- Fractions - $\frac{'{1}{2}'}$ - (1 and 2 represents the fraction)</div>
            <div>- Small lower character - $_2$ - (the 2 represents the character)</div>
            <div>- Small upper character - $^2$ - (the 2 represents the character)</div>
            <br/>
          </div>
          <form onSubmit={handleSubmit}>
            <label>
              <p className={classes.textHeading}> TEXT: </p>
            </label>
              <textarea ref={textAreaRef} className={classes.questionArea} value={question} onChange={handleChange} />

            <div style={{paddingTop: '8px'}}>
              <input  type="submit" value="Copy" />
            </div>
          </form>
          <div style={{paddingTop: '8px'}}>
            {copySuccess}
          </div>
          <div style={{padding: '32px 0 32px 0'}}>
            <hr/>
          </div>
          <div>
            <p className={classes.textHeading}>RENDER: </p>
            <ReactMarkDownComponent input={question}/>
            {/*<MarkdownRender source={question} />*/}
          </div>

        </Container>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
        </Grid>
      </Container>
    </React.Fragment>
  );
}