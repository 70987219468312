import React, {useEffect, useRef} from 'react';
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Link, useParams} from "react-router-dom";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Helmet} from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";

import servicesHomeData from "./configs/ServicesHomeData";
import Divider from "@material-ui/core/Divider";


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    padding: theme.spacing(14, 0, 6),
    // backgroundImage: `url(${wallPaper})`,
    backgroundColor: 'rgba(0, 0, 0, 0.013)',
    backgroundBlendMode: 'darken',
    backgroundSize: 'cover'
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  cardTitle: {
    fontWeight: 'bold',
    paddingBottom: '25px'
  },
  cardContentText: {
    paddingBottom: '20px',
    fontWeight: 'bold'
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // boxShadow: '0 1px 3px 0 rgba(37,32,31,.3)',
    boxShadow: '0 6px 2px 0 rgba(0,0,0,0.001)',
    border: '1px solid #ededed',
    borderRadius: '24px'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    marginBottom: '15px',
  },
  cardContent: {
    flexGrow: 1,
  },
  root: {
    paddingBottom: '80px',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  sections: {
    textAlign: 'center'
  },
  services: {
    marginTop: '30px'
  },
  sectionTitles: {
    fontWeight: 'bolder',
    color: 'black',
    paddingBottom: '30px',
    paddingTop: '64px'
  },
  servicesTagline: {
    // backgroundImage: `url(https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-31-01-24-13-44-19-zedautos.jpg)`,
    backgroundImage: `url(https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-01-02-24-10-24-22-zedautos.jpg)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    minHeight: '250px',
    borderRadius: '16px'

  },
  footer: {
    backgroundColor: '#2f3030',
    padding: theme.spacing(6),
    color: theme.palette.background.paper,
  },
  preFooter: {
    backgroundColor: '#dfe0e0',
    padding: theme.spacing(4),
    color: theme.palette.background.paper,
  },
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const WhiteTextTypography = withStyles({
  root: {
    color: "#000000"
  }
})(Typography);

export default function ServicesHomeComponent(props) {

  const classes = useStyles();
  const { c } = useParams();

  const homeServiceData = servicesHomeData[c]

  const dynamicStyle = {
    backgroundImage: `url(${homeServiceData.coverImage})`,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    minHeight: '250px',
    borderRadius: '16px'
  }

  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  const webRef = useRef(null)
  const executeWebScroll = () => scrollToRef(webRef)

  const location = useLocation();

  useEffect(() => {
    const section = location.section
    if (section === 'IT Support') {
      executeScroll()
    }
    else if (section === 'Web Design' || section === 'Digital Marketing') {
      executeWebScroll()
    } else {
      window.scrollTo(0, 0)
    }
  }, [location])


  const handleButtonClick = (slug) => {

    handleNavigate(slug)

  };

  let history = useHistory();

  function handleNavigate(slug) {
    history.push(`/services/${slug}`);
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta name="description"
              content="Consulting agency dedicated to delivering business services" />
        <title>Laminin Legal and Accounts - Business Consultancy Agency</title>
      </Helmet>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
            <BreadcrumbItem active>services</BreadcrumbItem>
          </Breadcrumb>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography
                  component="h1"
                  variant="h4"
                  align="left"
                  color="textPrimary"
                  style={{fontWeight: 'bold'}}
                  gutterBottom>
                <span style={{
                  backgroundColor: 'rgb(0,0,0)',
                  padding: '0.2em 0.2em 0.2em 0.2em',
                  color: 'white',
                  borderRadius: '5px',
                  lineHeight: '4rem'
                }}>
                  {homeServiceData.title}
                </span>
                  </Typography>
                  <br/>
                  <Typography>
                <span>
                  {homeServiceData.topSubTitle}
                </span>
                  </Typography>
                </Grid>
              <Grid item xs={12} sm={6}>
                {/*<div style={dynamicStyle}>*/}
                {/*</div>*/}
                <img
                  src={homeServiceData.coverImage}
                  style={{borderRadius: "16px", width: "90%", height: "auto"}}
                  alt="Laminin Legal Services"/>

              </Grid>
            </Grid>

          </Container>
        </div>
      </main>

      {/*Legal services list*/}
      <Container className={classes.cardGrid} style={{paddingTop: '64px'}} maxWidth="lg">
        <Grid container spacing={4} >
          {homeServiceData.servicesOffered.map((card) => (
            <Grid item key={card.id} xs={12} sm={6} md={4}>
              <Card className={classes.card} elevation={0}>
                <CardContent className={classes.cardContent}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    style={{fontWeight: 'bold', textAlign: 'left', color: '#978982'}}>
                    {card.title}
                  </Typography>
                  <Divider variant="middle"
                           style={{ marginBottom: '15px', marginTop: '15px', backgroundColor: 'rgb(0 0 0 / 5%)'}} />
                  <Typography style={{ textAlign: 'left', color: '#686868'}}>
                    {card.desc}
                  </Typography>
                </CardContent>
                <CardActions style={{marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    disableElevation={true}
                    style={{fontWeight: 800, border: '2px solid #000000'}}
                    onClick={() => handleButtonClick(card.slug)}>
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

    </React.Fragment>
  );
}