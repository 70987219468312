import React, {useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Helmet} from "react-helmet";
import MuiAlert from '@material-ui/lab/Alert';
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';
import ReactMarkDownComponent from './../ReactMarkDownComponent';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import {Col, Row} from "reactstrap";
import Divider from '@material-ui/core/Divider';

import Loader from 'react-loader-spinner';

import {useFetch, formatCourseName, handlePush, formatPaper, formatUrl} from "../../shared/utils";
import useLocalStorageState from "use-local-storage-state";

const scrollToRef = (ref) => window.scrollTo({top: ref.current.offsetTop, behavior: 'smooth'})

function displayContent(data) {
  if (data) {
    return(
      <div>
        <ReactMarkDownComponent input={data}/>
      </div>

    )
  }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    backgroundColor: '#ffffff',
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  questionHeaders: {
    fontWeight: 'bold',
    marginBottom: '8px',
    marginTop: '32px',
    color: 'black',
    backgroundColor: 'whitesmoke',
    borderRadius: '4px',
    padding: '4px'
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    backgroundColor: 'white'
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 6px 2px 0 rgba(0,0,0,0.08)'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
    marginBottom: '15px',
    marginTop: '20px',
  },
  cardContent: {
    flexGrow: 1,
  },
  metaData: {
    textAlign: 'center',
    fontWeight: 500,
    color: '#cccccc',
    fontSize: '12px',
    textTransform: 'capitalize'
  },
  metaDataPaper: {
    fontWeight: 500,
    color: '#cccccc',
    fontSize: '12px',
    textTransform: 'capitalize',
    display: 'inline-block',
    padding: '.4em .5em',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    textAlign: 'center',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '3px',
    backgroundColor: '#e4c9f3'
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch',
    },
  },
  buttonStyle: {
    width: '30ch'
  },
  drop: {
    width: '30ch',
    margin: '8px',
    paddingTop: '15px',
  },
  footer: {
    backgroundColor: '#2f3030',
    padding: theme.spacing(6),
    color: theme.palette.background.paper,
  },
  navigateButton: {
    border: '2px solid'
  },
  preFooter: {
    backgroundColor: '#dfe0e0',
    padding: theme.spacing(4),
    color: theme.palette.background.paper,
  },
}));


export default function PaperInsertView() {
  const classes = useStyles();
  const [next, setNext] = React.useState('/');
  const [previous, setPrevious] = React.useState('/');
  const [examPaper, setExamPaper] = React.useState("")
  const [paperURL, setPaperURL] = React.useState("")

  const [apiData, loading, error] = useFetch('paperInsert');

  const myRef = useRef()
  const executeScroll = () => scrollToRef(myRef)


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  },[]);

  useEffect(() => {

    if (apiData.paper_id) {
      let paper = formatUrl(apiData.paper_name)
      let paperLink  = `/revise/${apiData.rgn}/${apiData.cn}/${apiData.cid}/${apiData.exam_board}/${paper}/${apiData.paper_id}`
      let metaPaper = formatPaper(apiData.paper_name)
      setExamPaper(metaPaper)
      setPaperURL(paperLink)
    }
  },[apiData])

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
      <Container className={classes.cardGrid} style={{paddingTop: '10px'}} maxWidth="md">
        <Container maxWidth="md" component="main" className={classes.heroContent}>
          <div>
            <Row style={{paddingBottom: '25px', paddingTop: '8px'}}>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                { apiData.previous_question ?
                  <div style={{textAlign: 'left'}}>

                    <a href={previous} >
                      <Button size="small" className={classes.navigateButton}>
                        <NavigateBeforeRoundedIcon/>
                        BACK
                      </Button>
                    </a>
                  </div>
                  : null }
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                { apiData.curr_pos ?
                  <div className="div-center"
                       style={{textAlign: 'center', fontWeight: 500, color: '#cccccc', fontSize: '12px'}}>
                    {apiData.curr_pos} of {apiData.total}
                  </div>
                  : null }
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                { apiData.next_question ?
                  <div style={{textAlign: 'right'}}>
                    <a href={next}>
                      <Button size="small" className={classes.navigateButton}>
                        NEXT
                        <NavigateNextRoundedIcon />
                      </Button>
                    </a>
                  </div>
                  : null }
              </Col>

            </Row>
          </div>
          <Divider style={{backgroundColor: '#0000000f'}} />
          <div style={{marginTop: '16px'}} className={classes.metaDataPaper}>
            <a style={{color: '#2746a2'}} href={paperURL}>{examPaper}</a>
          </div>
          <Typography
            variant="h6"
            align="left"
            className={classes.questionHeaders}
            gutterBottom>
            INSERT:
          </Typography>

          <div style={{paddingBottom: '64px', paddingTop: '32px'}}>
            <Typography variant="h7" align="left" color="textSecondary" component="p">
              {apiData.inst ?
                <div>
                  <ReactMarkDownComponent input={apiData.inst}/>
                </div>:
                null
              }
            </Typography>
          </div>

        </Container>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
        </Grid>
      </Container>
    </React.Fragment>
  );
}