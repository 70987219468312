import React, {useEffect, useRef} from 'react';
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Link} from "react-router-dom";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Helmet} from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import ContactComponent from "./ContactComponent";

import servicesData from "./configs/ServicesData";
import DividerWithText from "./DividerWithText";


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    padding: theme.spacing(14, 0, 6),
    // backgroundImage: `url(${wallPaper})`,
    backgroundColor: 'rgba(0, 0, 0, 0.013)',
    backgroundBlendMode: 'darken',
    backgroundSize: 'cover'
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  cardTitle: {
    fontWeight: 'bold',
    paddingBottom: '25px'
  },
  cardContentText: {
    paddingBottom: '20px',
    fontWeight: 'bold'
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // boxShadow: '0 1px 3px 0 rgba(37,32,31,.3)',
    boxShadow: '0 6px 2px 0 rgba(0,0,0,0.001)',
    border: '1px solid #ededed',
    borderRadius: '24px'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    marginBottom: '15px',
  },
  cardContent: {
    flexGrow: 1,
  },
  root: {
    paddingBottom: '80px',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  sections: {
    textAlign: 'center'
  },
  services: {
    marginTop: '30px'
  },
  sectionTitles: {
    fontWeight: 'bolder',
    color: 'black',
    paddingBottom: '30px',
    paddingTop: '64px'
  },
  servicesTagline: {
    backgroundImage: `url(https://zedautos.s3.eu-west-2.amazonaws.com/a-2u-01-02-24-10-24-22-zedautos.jpg)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    minHeight: '250px',
    borderRadius: '16px'
  },
  footer: {
    backgroundColor: '#2f3030',
    padding: theme.spacing(6),
    color: theme.palette.background.paper,
  },
  preFooter: {
    backgroundColor: '#dfe0e0',
    padding: theme.spacing(4),
    color: theme.palette.background.paper,
  },
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const WhiteTextTypography = withStyles({
  root: {
    color: "#000000"
  }
})(Typography);

export default function ServicesDynamicComponent(props) {

  const classes = useStyles();

  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  const webRef = useRef(null)
  const executeWebScroll = () => scrollToRef(webRef)

  const location = useLocation();

  const { x } = useParams();

  // Retrieve the correct data based on `x`
  const serviceData = servicesData[x];

  const dynamicStyle = {
      backgroundImage: `url(${serviceData.coverImage})`,
      backgroundPosition: 'top center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
      minHeight: '250px',
      borderRadius: '16px'
  }

  useEffect(() => {
    const section = location.section
    if (section === 'IT Support') {
      executeScroll()
    }
    else if (section === 'Web Design' || section === 'Digital Marketing') {
      executeWebScroll()
    } else {
      window.scrollTo(0, 0)
    }
  }, [location])


  const handleButtonClick = () => {

    handleNavigate()

  };

  let history = useHistory();

  function handleNavigate() {
    history.push('/contact')
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta name="description"
              content="Consulting agency dedicated to delivering business services" />
        <title>Laminin Legal and Accounts - Business Consultancy Agency</title>
      </Helmet>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
            <BreadcrumbItem active>services</BreadcrumbItem>
          </Breadcrumb>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography
                  component="h1"
                  variant="h4"
                  align="left"
                  color="textPrimary"
                  style={{fontWeight: 'bold'}}
                  gutterBottom>
                <span style={{
                  backgroundColor: 'rgb(0,0,0)',
                  padding: '0.2em 0.2em 0.2em 0.2em',
                  color: 'white',
                  borderRadius: '5px',
                  lineHeight: '4rem'
                }}>
                  {serviceData.title}
                </span>
                </Typography>
                <br/>
                <Typography>
                <span>
                  {serviceData.topSubTitle}
                </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <img
                  src={serviceData.coverImage}
                  style={{borderRadius: "16px", width: "90%", height: "auto"}}
                  alt="Laminin Legal Services"/>

              </Grid>
            </Grid>

          </Container>
        </div>
      </main>

      {/*Legal services list*/}
      <Container className={classes.cardGrid} style={{paddingTop: '10px'}} maxWidth="lg">
        <WhiteTextTypography
          component="h1"
          variant="h4"
          align="left"
          style={{fontWeight: 'bold', marginTop: '64px', marginBottom: '40px'}}>

        </WhiteTextTypography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8} style={{paddingBottom: '32px'}}>
            {/*Insert the values of body here in bullet points, the key is in bold, followed by : and the value is in normal text*/}
            <ul>
              {serviceData.body.map((item, index) => {
                const key = Object.keys(item)[0];
                const value = item[key];
                return (
                  <li key={index}>
                    <Typography component="span" variant="body1" style={{fontWeight: 'bold'}}>{key}: </Typography>
                    <Typography component="span" variant="body1">{value}</Typography>
                    <br/>
                    <br/>
                  </li>
                );
              })}
            </ul>
            <br/>
            <Typography component="span" variant="body1">
              {serviceData.conclusion}
            </Typography>

          </Grid>
          <Grid item xs={12} sm={4} style={{backgroundColor: "whitesmoke"}}>
            <div  style={{fontWeight: 'bold', paddingTop: '32px', textAlign: "center"}}>
              <Typography
                component="span"
                variant="h5"
                align="center"
                style={{fontWeight: 'bold', paddingTop: '64px'}}>
                Speak to an expert today
              </Typography>
              <br/>
              <br/>
              <Typography
                component="span"
                variant="h6"
                align="center"
                style={{fontWeight: 'bolder', paddingTop: '32px', color: '#000000'}}>
                0208 087 1636
              </Typography>
            </div>
            <br/>
            <br/>
            <DividerWithText/>
            <br/>
            <br/>
            <ContactComponent/>

          </Grid>
        </Grid>
      </Container>

    </React.Fragment>
  );
}